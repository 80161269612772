const baseUrl = "/mkt/discovery";

export interface PageParam  {

}

//定义表格的属性
export interface TableDataType {
    pcBannerPic: string;
    appBannerPic: string;
    oldPcBannerPic: string;
    oldAppBannerPic: string;
    infoType: string;
    enableStatus: string;
    stick: number;
    title: string;
    introduce: string;
    startTime: string;
    endTime: string;
    jumpLink: string;
    pushScope: string;

}

export  {baseUrl};