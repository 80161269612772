import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/ord/ordPromotionMonthStatistics";

const ordPromotionStatisticsApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";

        if (body) {
            if (body.yearMonth) {
                url += 'yearMonth=' + encodeURIComponent(body.yearMonth)+"&";
            }
        }
        if (body) {
            if (body.userId) {
                url += 'userId=' + encodeURIComponent(body.userId)+"&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    monthList(userId){
        let url=baseUrl;
        url+="/monthList?userId="+userId
        return   request.get<OptionRes[]>(url)
    }

}

export default ordPromotionStatisticsApi



