import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/ord/order";
import commonApi from "../commonApi";

const orderApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.id) {
                url += 'id=' + encodeURIComponent(body.id) + "&";
            }
            if (body.orderTypeCd) {
                url += 'orderTypeCd=' + encodeURIComponent(body.orderTypeCd) + "&";
            }
            if (body.promotionCode) {
                url += 'promotionCode=' + encodeURIComponent(body.promotionCode) + "&";
            }
            if (body.businessTypeCd) {
                url += 'businessTypeCd=' + encodeURIComponent(body.businessTypeCd) + "&";
            }
            if (body.createTimeBegin) {
                url += 'createTimeBegin=' + encodeURIComponent(body.createTimeBegin) + "&";
            }
            if (body.createTimeEnd) {
                url += 'createTimeEnd=' + encodeURIComponent(body.createTimeEnd) + "&";
            }
            if (body.finishTimeBegin) {
                url += 'finishTimeBegin=' + encodeURIComponent(body.finishTimeBegin) + "&";
            }
            if (body.finishTimeEnd) {
                url += 'finishTimeEnd=' + encodeURIComponent(body.finishTimeEnd) + "&";
            }
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber) + "&";
            }
            if (body.orderStatusCd) {
                url += 'orderStatusCd=' + encodeURIComponent(body.orderStatusCd) + "&";
            }
            if (body.paymentCode) {
                url += 'paymentCode=' + encodeURIComponent(body.paymentCode) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    // //批量删除
    // batchDelete(uidList: Number[]) {
    //     let url = baseUrl;
    //     if (uidList) {
    //         url += "?"
    //         const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
    //         url += params;
    //     }
    //     return request.delete(url);
    // },
    // //创建新表单
    // create(body: any) {
    //     return request.post(baseUrl, body)
    // },
    //更新数据
    updStatus(body: any) {
        let url = baseUrl + '/updStatus';
        return request.put(url, body)
    },
    // //查询列表
    // options() {
    //     let url = baseUrl;
    //     url += "/options?organType=O01"
    //     return request.get<OptionRes[]>(url)
    // },

    searchOrderComStatusLog(id: Number) {
        let url = baseUrl + "/log?orderId=" + id;
        return request.get(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"订单列表",body)
    },
}

export default orderApi;



