import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/sub/subUserPromotionRel";
import commonApi from "../commonApi";

const subUserPromotionRelApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber)+"&";
            }
            if (body.preinstallGroupCode) {
                url += 'preinstallGroupCode=' + encodeURIComponent(body.preinstallGroupCode)+"&";
            }
            if (body.promoteByUserId) {
                url += 'promoteByUserId=' + encodeURIComponent(body.promoteByUserId)+"&";
            }
            if (body.userStatus) {
                url += 'userStatus=' + encodeURIComponent(body.userStatus)+"&";
            }
            if (body.sortBy) {
                url += 'sortBy=' + encodeURIComponent(body.sortBy)+"&";
            }
            if (body.ascending) {
                url += 'ascending=' + encodeURIComponent(body.ascending)+"&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"推广会员列表",body)
    },
    // optinos(){
    //     let url=baseUrl;
    //     url+="/options"
    //     return   request.get<OptionRes[]>(url)
    // }

}

export default subUserPromotionRelApi



