import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    DatePicker, Divider,
    Form,
    Image,
    Input,
    message,
    Modal, Row,
    Select,
    Upload,
    type UploadFile,
    type UploadProps
} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import discoveryApi from "../../../../apis/mkt/discovery/discoveryApi";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../apis/types/mkt/discovery/discoveryVo";
import {domain, imgIp} from "../../../../components/constantConfig";
import {getToken} from "../../../../utils";
import {UploadOutlined} from "@ant-design/icons";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface DiscoveryProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    model: String;
    record: TableDataType;
    enableStatusOption: OptionRes[];
    interactOption: OptionRes[];
    infoTypeOption: OptionRes[];
    pushScopeOption: OptionRes[];
}

const DiscoveryForm: React.FC<DiscoveryProps> = ({
                                                                   refresh,
                                                                   open,
                                                                   closeOpen,
                                                                   model,
                                                                   record,
                                                                   enableStatusOption,
                                                                   interactOption,
                                                                   infoTypeOption,
                                                                    pushScopeOption,
                                                               }) => {
    const [form] = Form.useForm();

    const validEndTime = (_, value) => {
        const now = dayjs() // 获取当前时间的值
        const startTime = form.getFieldValue('startTime');// 获取开始时间的值
        if (value && now && !value.isAfter(now)) {
            return Promise.reject('请求失败 活动时间不能小于当前时间');
        }
        if(value && startTime && !value.isAfter(startTime)){
            return Promise.reject('请求失败 活动结束时间不能小于开始时间');
        }
        return Promise.resolve();
    };

    useEffect(() => {
        setPcBannerPicFile("");
        setOldPcBannerPicFile("");
        console.log("record",record)
        if (open && record) {
            form.setFieldsValue({
                ...record,
                infoType: record.infoType !== null && record.infoType !== undefined
                    ? record.infoType.toString():null,
                enableStatus: record.enableStatus !== null && record.enableStatus !== undefined
                    ? record.enableStatus.toString():null,
                stick: record.stick !== null && record.stick !== undefined
                    ? record.stick.toString():null,
                pushScope: record.pushScope !== null && record.pushScope !== undefined
                    ? record.pushScope.toString():null,
            })
            record.enableStatus = form.getFieldValue('enableStatus');
        }

    }, [open]);

    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0 && model ==='edit') {

            form.setFieldsValue({
                ...record,
                startTime: dayjs(record.startTime),
                endTime: dayjs(record.endTime),
            })
        }

    }, [open]);


    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();

        values = {
            ...values,
            // 格式化时间为接口需要的格式
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.pcBannerPic = pcBannerPic;
        values.appBannerPic = appBannerPic;

        // 校验通过，可以提交数据
        discoveryApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    const handleUpdate = () => {
        let values = form.getFieldsValue();

        values = {
            ...values,
            // 格式化时间为接口需要的格式
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.pcBannerPic = pcBannerPic;
        values.appBannerPic = appBannerPic;

        // 校验通过，可以提交数据
        discoveryApi.update(values.id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.setFieldValue("pcBannerPic",pcBannerPic);
        form.setFieldValue("appBannerPic",appBannerPic);
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            console.log('values',values)
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {
            // 校验失败，可以处理错误信息
            console.log('校验失败:', error);
            });
    }

    const [pcBannerPic, setPcBannerPicFile] = useState(record.pcBannerPic)
    const [oldPcBannerPic, setOldPcBannerPicFile] = useState(record.oldPcBannerPic);
    const [appBannerPic, setAppBannerPicFile] = useState(record.appBannerPic)
    const [oldAppBannerPic, setOldAppBannerPicFile] = useState(record.oldAppBannerPic);

    useEffect(() => {
        setPcBannerPicFile("");
        setOldPcBannerPicFile("");
        // 在组件挂载时发送请求
        if (record.pcBannerPic) {
            // 发送请求的逻辑，使用 record.id 去获取数据
            // 比如：fetchData(record.id);
            console.log('Sending request for record:', record);
            setPcBannerPicFile(record.pcBannerPic);
            setOldPcBannerPicFile(record.pcBannerPic);
        }
    }, [record.pcBannerPic]);

    useEffect(() => {
        setAppBannerPicFile("");
        setOldAppBannerPicFile("");
        // 在组件挂载时发送请求
        if (record.appBannerPic) {
            // 发送请求的逻辑，使用 record.id 去获取数据
            // 比如：fetchData(record.id);
            console.log('Sending request for record:', record);
            setAppBannerPicFile(record.appBannerPic);
            setOldAppBannerPicFile(record.appBannerPic);
        }
    }, [record.appBannerPic]);

    const PCBannerUploadProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: discoveryApi.getUploadBannerUrl,
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setIconFileList([...iconFileList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let pcBannerUrlRes = file.response.data;
                    console.log("done,", pcBannerUrlRes)
                    setPcBannerPicFile(pcBannerUrlRes)
                    // setIconFileList([...iconFileList,file])
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.webp'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('仅支持JPG、PNG、webp格式图片');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小不能超过2MB');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setOldPcBannerPicFile(oldPcBannerPic)
        },

    };

    const appBannerUploadProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: discoveryApi.getUploadBannerUrl,
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setIconFileList([...iconFileList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let appBannerUrlRes = file.response.data;
                    console.log("done,", appBannerUrlRes)
                    setAppBannerPicFile(appBannerUrlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.webp'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('仅支持JPG、PNG、webp格式图片');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小不能超过2MB');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setOldAppBannerPicFile(oldAppBannerPic)
        },

    };

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "活动编辑"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}

        >

            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >

                <Form.Item name="id" label="发现页编号" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={1}>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="infoType" label="活动类型" rules={[{required: true, message: "活动类型必填"}]}>
                            <Select
                                style={{width: 180}}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={infoTypeOption}
                                disabled={model === 'edit'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="title" label="标题" rules={[{required: true, message: "标题必填"}]}>
                            <Input style={{maxWidth: 180}} maxLength={21}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="introduce" label="简介" rules={[{required: true, message: "简介必填"}]}>
                            <TextArea rows={3} style={{width: 300}} maxLength={24}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="startTime" label="开始时间" rules={[{required: true, message: "开始时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width:200}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="endTime" label="结束时间" rules={[{required: true, message: "结束时间必填"},{validator: validEndTime}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width:200}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={1}>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="enableStatus" label="启用状态" rules={[{required: true, message: "启用状态必填"}]}>
                            <Select
                                style={{width: 180}}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={enableStatusOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="stick" label="置顶" rules={[{required: true, message: "置顶必填"}]}>
                            <Select
                                style={{width: 180}}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={[{value: 0, label: '不置顶'}, {value: 1, label: '置顶'}]}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="jumpLink" label="跳转链接" rules={[{required: true, message: "跳转链接必填"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item style={{margin: 5}} name="pushScope" label="推送范围" rules={[{required: true, message: "推送范围必填"}]}>
                            <Select
                                style={{width: 180}}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={pushScopeOption}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{margin: 20}}>
                    <Col span={6}>
                        <Form.Item name="pcBannerPic" label="PC端banner图片（尺寸建议250*130）" rules={[{required: true, message: "PC端banner图片必填"}]}
                                   labelCol={{ span: 19 }} // 控制标签的栅格
                                   wrapperCol={{ span: 4 }} // 控制内容的栅格
                        >
                            <Image src={pcBannerPic?imgIp+pcBannerPic:pcBannerPic} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appBannerPic" label="移动端banner图片（尺寸建议702*280）" rules={[{required: true, message: "移动端banner图片必填"}]}
                                   labelCol={{ span: 20 }} // 控制标签的栅格
                                   wrapperCol={{ span: 4 }} // 控制内容的栅格
                        >
                            <Image src={appBannerPic?imgIp+appBannerPic:appBannerPic} width={100}/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col span={6}>
                        <Form.Item name="PCBannerUplod" label="PC端banner图片上传"
                                   labelCol={{ span: 13 }} // 控制标签的栅格
                                   wrapperCol={{ span: 9 }} // 控制内容的栅格
                        >
                            <Upload {...PCBannerUploadProps} >
                                <Button style={{width: 100}} icon={<UploadOutlined/> }>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appBannerPicUpload" label="移动端banner图片上传"
                                   labelCol={{ span: 14 }} // 控制标签的栅格
                                   wrapperCol={{ span: 9 }} // 控制内容的栅格
                        >
                            <Upload {...appBannerUploadProps} >
                                <Button style={{width: 100}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={1}>
                    </Col>
                    <Col span={3}>
                        <Form.Item style={{margin: 5}} name="notes" label="备注">
                            <TextArea rows={1} style={{width: 300}} maxLength={50}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default DiscoveryForm;



