import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";
import {PageParam, baseUrl, TableDataType} from "../types/ord/agentOrder";

const agentOrderApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.id) {
                url += 'id=' + encodeURIComponent(body.id) + "&";
            }
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber) + "&";
            }
            if (body.goodsId) {
                url += 'goodsId=' + encodeURIComponent(body.goodsId) + "&";
            }
            if (body.createTimeBegin) {
                url += 'createTimeBegin=' + encodeURIComponent(body.createTimeBegin) + "&";
            }
            if (body.createTimeEnd) {
                url += 'createTimeEnd=' + encodeURIComponent(body.createTimeEnd) + "&";
            }
            if (body.redeemType) {
                url += 'redeemType=' + encodeURIComponent(body.redeemType) + "&";
            }
            if (body.payStatusCd) {
                url += 'payStatusCd=' + encodeURIComponent(body.payStatusCd) + "&";
            }
            if (body.paymentCode) {
                url += 'paymentCode=' + encodeURIComponent(body.paymentCode) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    derive(body: PageParam) {
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url, "代理商订单列表", body)
    },
}

export default agentOrderApi;



