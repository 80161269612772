import moment from "moment";

const baseUrl = "/pdt/goods";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    goodsTypeCd:string,
    goodsName:string,
    goodsCode:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    goodsTypeCdDesc: string;
    goodsTypeCd: string;
    goodsName: string;
    goodsCode: string;
    goodsIcon: string;
    productIdDesc: string;
    goodsDetail: string;
    advertWords: string;
    saleModeCdDesc: string;
    saleObjectCdDesc: string;
    displayModeCdDesc: string;
    priceIdDesc: string;
    priceId: number;
    autoRenewal: string;
    listingTime: string;
    delistingTime: string;
    statusCdDesc: string;
    reorder: number;
    notes: string;
    goodsIconUnselected:string;
    cornerIcon:string;
    recommend:number;
    recommendDesc:string;
    defaultSelected:number;
    defaultSelectedDesc:string;
    insPoolId:number;
    duration:number;

}



export  {baseUrl};

