import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Modal, Select} from 'antd';
import {OptionRes} from "../../../../apis/types/common";
import agentPriceRelApi from "../../../../apis/pdt/pdtAgentPriceRel";
import goodsApi from "../../../../apis/pdt/goods";
import {TableDataType} from "../../../../apis/types/pdt/pdtAgentPriceRel";
import agentGoodsPriceApi from "../../../../apis/pdt/agentGoodsPrice";
import propertiesApi from "../../../../apis/sys/properties";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface FormProps {
    addItem: (newItem) => void;
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: number;
    agentUserId: number
}


const PdtAgentPriceRelForm: React.FC<FormProps> = ({
                                                       addItem,
                                                       refresh,
                                                       record,
                                                       open,
                                                       closeOpen,
                                                       model,
                                                       agentUserId
                                                   }) => {
    const [agentUserForm] = Form.useForm();
    const [goodsOption, setGoodsOption] = useState<OptionRes[]>([])
    const [redeemTypeOption, setRedeemTypeOption] = useState<OptionRes[]>([])
    const [priceOption, setPriceOption] = useState<OptionRes[]>([])

    function queryPriceOption(){
        let goodsId = agentUserForm.getFieldValue("goodsId");
        let redeemType = agentUserForm.getFieldValue("redeemType");
        if (goodsId&&redeemType){
            agentGoodsPriceApi.options(goodsId,redeemType).then((result) => {
                if (result) {
                    setPriceOption(result.data);
                }
            })
        }
    }

    function handleQuery(){
        agentUserForm.setFieldValue("priceId",null)
        queryPriceOption()
    }

    useEffect(() => {
        agentUserForm.resetFields()
        setPriceOption([]);
        if (model == 1) {
            agentUserForm.setFieldsValue({
                ...record,
                pwd: ''
            });
        }
        goodsApi.options(null, 'G07').then((result) => {
            if (result) {
                setGoodsOption(result.data);
            }
        })
        propertiesApi.optinos("DM_Redeem_Type").then((result) => {
            if (result) {
                setRedeemTypeOption(result.data);
            }
        })
        queryPriceOption()
    }, [open])

    //处理新增方法
    const handleCreate = () => {
        let values = agentUserForm.getFieldsValue();
        if (!values.id){
            values={...values,"id":performance.now()}
        }
        for (let i = 0; i < goodsOption.length; i++) {
            if (values.goodsId==goodsOption[i].value){
                values={...values,"goodsName":goodsOption[i].label}
            }
        }
        for (let i = 0; i < redeemTypeOption.length; i++) {
            if (values.redeemType==redeemTypeOption[i].value){
                values={...values,"redeemTypeDesc":redeemTypeOption[i].label}
            }
        }
        for (let i = 0; i < priceOption.length; i++) {
            if (values.priceId==priceOption[i].value){
                values={...values,"price":priceOption[i].label}
            }
        }
        if (values) {
            console.log("values",values)
            addItem(values);  // 调用父组件的addItem函数，传递新增数据
            agentUserForm.resetFields();
            closeOpen();
        }
    };

    //处理取消方法
    const handleCancel = () => {
        agentUserForm.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        handleCreate();
    }


    //处理ok按钮，
    function handleOK() {
        agentUserForm.validateFields().then(() => {
            const values = agentUserForm.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={agentUserForm}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="createTime" label="创建时间" hidden={true}>
                </Form.Item>
                <Form.Item name="creater" label="创建人" hidden={true}>
                </Form.Item>
                <Form.Item name="modifyTime" label="修改时间" hidden={true}>
                </Form.Item>
                <Form.Item name="modifier" label="修改人" hidden={true}>
                </Form.Item>
                <Form.Item name="enableStatus" label="启用状态" hidden={true}>
                </Form.Item>
                <Form.Item name="softDel" label="是否删除" hidden={true}>
                </Form.Item>
                <Form.Item name="goodsId" label="兑换码商品"
                           rules={[{required: true, message: "兑换码商品不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={goodsOption}
                        onChange={handleQuery}
                    />
                </Form.Item>
                <Form.Item name="redeemType" label="兑换码类型"
                           rules={[{required: true, message: "兑换码类型不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={redeemTypeOption}
                        onChange={handleQuery}
                    />
                </Form.Item>
                <Form.Item name="priceId" label="价格(单位：分)"
                           rules={[{required: true, message: "价格不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={priceOption}
                    />
                </Form.Item>
                <Form.Item name="quantity" label="数量"
                           rules={[{required: true, message: "数量不能为空"},{type:'number',min:1,max:999,message:"数量不能大于999或小于1"}]}>
                    <InputNumber min={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default PdtAgentPriceRelForm;



