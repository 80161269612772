import {baseUrl, PageParam, TableDataType} from "../../types/mkt/act/act";
import {PaginationProps} from "antd";
import {request} from "../../../utils";
import {PageRes} from "../../types/common";
import commonApi from "../../commonApi";


const actApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl+"/page";
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.actType) {
                url += 'actType=' + encodeURIComponent(body.actType) + "&";
            }
            if (body.actTypeList) {
                url += 'actTypeList=' + encodeURIComponent(body.actTypeList) + "&";
            }
            if (body.startStartTime) {
                url += 'startStartTime=' + encodeURIComponent(body.startStartTime) + "&";
            }
            if (body.endStartTime) {
                url += 'endStartTime=' + encodeURIComponent(body.endStartTime) + "&";

            }
            if (body.startEndTime) {
                url += 'startEndTime=' + encodeURIComponent(body.startEndTime) + "&";

            }
            if (body.endEndTime) {
                url += 'endEndTime=' + encodeURIComponent(body.endEndTime) + "&";
            }
            if (body.actCode) {
                url += 'actCode=' + encodeURIComponent(body.actCode) + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + encodeURIComponent(body.enableStatus) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl+"/delete";
        let body = { idList: idList };

        return request.post(url,body);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/create", body)
    },
    //更新数据
    update(body: any) {
        let url = baseUrl+"/update" ;
        return request.post(url, body)
    },

    //禁用数据
    disable(body: any) {
        let url = baseUrl+"/disable" ;
        return request.post(url, body)
    },
    //启用数据
    enable(body: any) {
        let url = baseUrl+"/enable" ;
        return request.post(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"活动列表",body)
    },

    //查询子数据列表
    actOthers(body:any){
        let url = baseUrl+"/others" ;
        return request.post(url, body)
    }



}

export default actApi




