import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/pdt/goods";
import commonApi from "../commonApi";

const goodsApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.goodsTypeCd) {
                url += 'goodsTypeCd=' + encodeURIComponent(body.goodsTypeCd) + "&";
            }
            if (body.goodsName) {
                url += 'goodsName=' + body.goodsName + "&";
            }
            if (body.goodsCode) {
                url += 'goodsCode=' + body.goodsCode + "&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询列表
    options(productId?:number,goodsType?:string,goodsTypes?:string[]) {
        let url = baseUrl;
        url += "/options"
        let body={}
        if (productId){
            body={...body,"productId":productId}
        }
        if (goodsType){
            body={...body,"goodsType":goodsType}
        }
        if (goodsTypes){
            body={...body,"goodsTypes":goodsTypes}
        }
        return request.post<OptionRes[]>(url,body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"商品列表",body)
    },

}

export default goodsApi



