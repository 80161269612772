const baseUrl = "/sub/agent/user";
/** 获取列表-参数 */
export interface PageParam {
    userName: string;
    userCode: string;
    price: number;
    notes: string;
    poolId: number;
    redeemType: string;
    goodsId:number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    userName: string;
    userCode: string;
    mobile: string;
    pwd: string;
    confirmPwd: string;
    poolId: number;
    poolName: string;
    createTime: string;
    creater: number;
    createrName: string;
    modifier: number;
    modifierName: string;
    modifyTime: string;
    enableStatus: number;
    softDel: number;
    notes: string;
}

export  {baseUrl};