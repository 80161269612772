import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import productApi from "../../../../../apis/pdt/product";
import {TableDataType} from "../../../../../apis/types/pdt/agentGoodsPrice";
import {OptionRes} from "../../../../../apis/types/common";
import agentGoodsPriceApi from "../../../../../apis/pdt/agentGoodsPrice";
import {filterOption} from "../../../../../apis/common";
import Details from "../../../mkt/redeemCode/details";
import AgentGoodsPriceForm from "./agentGoodsPriceForm";


const AgentGoodsPriceContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        productApi.options(1).then((result) => {
            if (result) {
                setProductIdOption(result.data);
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState<number>(0)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [productIdOption, setProductIdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        console.log("pageSize", pageSize)
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = agentGoodsPriceApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })
    }

    //处理删除
    const handleDelete = () => {
        agentGoodsPriceApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });
    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        agentGoodsPriceApi.derive(body);
    }

    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(1);
                }}>
                    编辑
                </Typography.Link>
            },
            width: 50
        },
        {
            title: '所属产品',
            dataIndex: 'productName',
            key: 'productName',
            ellipsis: true,
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            ellipsis: true,
        },

        {
            title: '类型',
            dataIndex: 'redeemTypeDesc',
            key: 'redeemTypeDesc',
            ellipsis: true,
        },
        {
            title: '价格（分）',
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'createrName',
            key: 'createrName',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
        },
        {
            title: '修改人',
            dataIndex: 'modifierName',
            key: 'modifierName',
            ellipsis: true,
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setType(0)
                    setRowData({} as TableDataType)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="goodsName" label="商品名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="productId" label="所属产品">
                        <Select
                            allowClear
                            style={{width: 150}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={productIdOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                agentGoodsPriceApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <AgentGoodsPriceForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                closeOpen={() => {
                    setOpen(false);
                }}
                open={open}
                productIdOption={productIdOption}
            ></AgentGoodsPriceForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default AgentGoodsPriceContent;