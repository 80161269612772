import React, {useEffect, useState} from 'react';
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Upload, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../../apis/types/pdt/customProduct";
import {filterOption} from "../../../../../apis/common";
import {OptionRes} from "../../../../../apis/types/common";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../../components/constantConfig";
import propertiesApi from "../../../../../apis/sys/properties";
import customProductApi from "../../../../../apis/pdt/customProduct";
import channelParentVersionApi from "../../../../../apis/pub/channel/channelParentVersion";
import productConfigApi from "../../../../../apis/app/productConfig";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    productId: number;
    statusCdOption: OptionRes[];
}


const ProductCustomFrom: React.FC<FormProps> = ({
                                                    refresh, record, open, closeOpen, model,
                                                    productId,
                                                }) => {
    const [form] = Form.useForm();

    const [enableOptions, setEnableOptions] = useState<OptionRes[]>([])
    const [filePath, setFilePath] = useState()

    const [fileList, setFileList] = useState<UploadFile[]>([])
    // 编辑的时候回显数据
    useEffect(() => {
        propertiesApi.optinos("DM_ProductAtt_Status").then((res) => {
            if (res) {
                setEnableOptions(res.data)
            }
        })
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
            console.log("ProductAttributeFrom productId: ", record.productId)
            // setIconUrl("")
            // setFileList([])
        }
    }, [open]);

    useEffect(() => {
        console.log("Attribute Id:  " + record.id)
        // setFileList([]);
    }, [record.id]); // 当 record 变化时触发

    //处理新增方法
    const handleCreate = () => {
        const formData = form.getFieldsValue();
        formData.productId = productId; // 添加 productId 键值对
        customProductApi.create(formData).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
        setFileList([]);
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        values.productId = productId; // 添加 productId 键值对
        values.productAttributedId = values.id; // 添加 productId 键值对
        let valueJSOn = JSON.stringify(values);
        customProductApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const props: UploadProps = {
        action: customProductApi.getUploadUrl(),
        headers: {
            authorization: getToken(),
        },
        multiple: false,
        maxCount: 1,
        fileList: fileList,
        data: { // 添加 data 参数
            productId: productId // 获取表单中的 versionCode
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    // let downloadUrl = file.response.data.downloadUrl;
                    // console.log("done,downloadUrl:", downloadUrl)
                    // setDownloadUrl(downloadUrl)

                    let filePath = file.response.data.filePath;
                    setFilePath(filePath);
                    form.setFieldsValue({filePath})  // 设置 fileSize

                    let fileSize = file.response.data.fileSize;
                    form.setFieldsValue({fileSize})  // 设置 fileSize
                    setFileList(prevList => [...prevList, file]);
                    console.log("done,filePath:{}, fileSize:{}, ", filePath, fileSize)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setFileList([...fileList])
        },
        beforeUpload(file) {
            const allowedExtensions = ['.apk'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传apk文件');
                return false;
            }
            const isLt2000M = file.size / 1024 / 1024 < 3072;
            if (!isLt2000M) {
                message.error('文件大小不能超过3G');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setFilePath(null)
        },

    };
    return (
        <Modal
            title={model == 'create' ? "新增应用" : "编辑应用"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >

                <Form.Item name="filePath" label="应用文件路径" hidden={true}>
                </Form.Item>
                <Form.Item name="uploadObj" label="应用">
                    <Upload {...props}>
                        <Button icon={<UploadOutlined/>} style={{width:250}}>Upload</Button>
                    </Upload>
                </Form.Item>
            
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ProductCustomFrom;



