import React, {useEffect, useState} from 'react';
import {
    Col,
    Form,
    Modal,
    PaginationProps,
    Row, Tabs, TabsProps,
    Typography
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/ord/ordPromotionStatistics";
import configApi from "../../../../apis/ord/ordPromotionStatistics";
import ordPromotionStatisticsApi from "../../../../apis/ord/ordPromotionStatistics";
import {ColumnsType} from "antd/es/table";
import OrdPromotionMonthStatisticsContent from "../promotionMonthStatistics/ordPromotionMonthStatisticsContent";
import SubUserPromotionRelContent from "../../sub/subUserPromotionRel/subUserPromotionRelContent";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    statusCdOption: OptionRes[];
}


const OrdPromotionStatisticsForm: React.FC<FormProps> = ({
                                                             refresh,
                                                             record,
                                                             open,
                                                             closeOpen,
                                                             statusCdOption
                                                         }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '推广数据明细',
            children: <OrdPromotionMonthStatisticsContent userId={record.userId}/>,
        },
        {
            key: '2',
            label: '用户明细',
            children: <SubUserPromotionRelContent  promoteByUserId={record.userId}/>,
        }
    ];

    return (
        <Modal
            title
            open={open}
            footer={null}
            onCancel={handleCancel}
            width={'80%'}
        >
            <div>
                <div>
                    <p>推广员详情</p>
                    <Row>
                        <Col span={6}>推广编码：{record.promotionCode}</Col>
                        <Col span={6}>会员编码：{record.subscriberCode}</Col>
                        <Col span={6}>会员昵称：{record.nickName}</Col>
                        <Col span={6}>手机号码：{record.phoneNumber}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>收款名称：{record.payeeName}</Col>
                        <Col span={6}>收款账号：{record.payeeAlipayAccount}</Col>
                        <Col span={6}>用户状态：{record.userStatusDesc}</Col>
                        <Col span={6}>开通推广时间：{record.promotionOpenTime}</Col>
                    </Row>
                </div>
                <div style={{marginTop:'20px'}}>
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            </div>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default OrdPromotionStatisticsForm;



