import React, {useEffect, useState} from 'react';
import {Col, Form, InputNumber, message, Modal, Radio, Row, Select, Space} from 'antd';
import {OptionRes} from "../../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../../apis/types/pdt/agentGoodsPrice";
import agentGoodsPriceApi from "../../../../../apis/pdt/agentGoodsPrice";
import goodsApi from "../../../../../apis/pdt/goods";
import propertiesApi from "../../../../../apis/sys/properties";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface agentGoodsPriceFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    productIdOption: OptionRes[]
}


const AgentGoodsPriceForm: React.FC<agentGoodsPriceFormProps> = ({
                                                                     refresh,
                                                                     record,
                                                                     open,
                                                                     closeOpen,
                                                                     productIdOption,
                                                                 }) => {
    const [form] = Form.useForm();
    const [goodsOption, setGoodsOption] = useState<OptionRes[]>([])
    const [redeemTypeOption, setRedeemTypeOption] = useState<OptionRes[]>([])

    useEffect(() => {
        form.resetFields()
        if (record) {
            form.setFieldsValue({
                ...record,
            });
        }
        propertiesApi.optinos('DM_Redeem_Type').then((res) => {
            if (res) {
                setRedeemTypeOption(res.data)
            }
        })
        let productId = form.getFieldValue("productId");
        if (productId) {
            goodsApi.options(productId,"G07").then((res) => {
                if (res) {
                    setGoodsOption(res.data)
                }
            })
        }
    }, [open])

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        agentGoodsPriceApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("新增成功");
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        agentGoodsPriceApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("修改成功");
            }
        });
    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    function queryGoodsOption() {
        let productId = form.getFieldValue("productId");
        if (productId) {
            goodsApi.options(productId,"G07").then((res) => {
                if (res) {
                    setGoodsOption(res.data)
                    form.setFieldValue("goodsId", null);
                }
            })
        }
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="createTime" label="创建时间" hidden={true}>
                </Form.Item>
                <Form.Item name="creater" label="创建人" hidden={true}>
                </Form.Item>
                <Form.Item name="modifyTime" label="修改时间" hidden={true}>
                </Form.Item>
                <Form.Item name="modifier" label="修改人" hidden={true}>
                </Form.Item>
                <Form.Item name="enableStatus" label="启用状态" hidden={true}>
                </Form.Item>
                <Form.Item name="softDel" label="是否删除" hidden={true}>
                </Form.Item>
                <Form.Item name="productId" label="所属产品"
                           rules={[{required: true, message: "所属产品不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={productIdOption}
                        onChange={queryGoodsOption}
                    />
                </Form.Item>
                <Form.Item name="goodsId" label="商品名称"
                           rules={[{required: true, message: "商品名称不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={goodsOption}
                    />
                </Form.Item>

                <Form.Item name="redeemType" label="类型"
                           rules={[{required: true, message: "类型不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={redeemTypeOption}
                    />
                </Form.Item>
                <Form.Item name="price" label="价格（分）"
                           rules={[{required: true, message: "价格必填"},{whitespace:true,validator:(rule,value,callback)=>{
                                   if (!/^\d+$/.test(value)){
                                       callback("请输入正整数");
                                   }else {
                                       callback();
                                   }
                               }}]}>
                    <InputNumber/>
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max:255,message:"备注长度不可超过255个字符"}]}>
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default AgentGoodsPriceForm;



