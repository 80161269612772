const baseUrl = "/pdt/agent/goods/price";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    goodsName:string,
    productId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    goodsId: number;
    goodsName: string;
    productId: number;
    productName: string;
    duration: number;
    durationText: string;
    redeemType: string;
    redeemTypeDesc: string;
    price: number;
    createTime: string;
    creater: number;
    createrName: string;
    modifier: number;
    modifierName: string;
    modifyTime: string;
    enableStatus: number;
    softDel: number;
    notes: string;
}



export  {baseUrl};

