const baseUrl = "/ord/agent/order";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    id:number,
    phoneNumber:string,
    goodsId:string,
    createTimeBegin:string,
    createTimeEnd:string,
    redeemType:string,
    payStatusCd:string,
    paymentCode:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    agentUserId: number;
    mobile: string;
    productId: number;
    goodsId: number;
    goodsName: string;
    priceId: number;
    quantity: number;
    price: number;
    orderAmount: number;
    paymentAmount: number;
    redeemType: string;
    redeemTypeDesc: string;
    duration: number;
    orderStatusCd: string;
    payStatusCd: string;
    payStatusCdDesc: string;
    payModeId: number;
    payModeName: string;
    orderCode: string;
    paymentCode: string;
    paymentSerial: string;
    batchNo: string;
    notes: string;
    orderSnapshot: string;
    payTime: string;
    createTime: string;
    creater: number;
    sourceIp: string;
}



export  {baseUrl};

