const baseUrl = "/sub/user/promotion/rel";
/** 获取列表-参数 */
export interface PageParam {
    phoneNumber: string;
    preinstallGroupCode: string;
    userStatus: string;
    promoteByUserId: number;
    sortBy: string;
    ascending: boolean;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    userId: number;
    promoteByUserId: number;
    subscriberCode: string;
    nickName: string;
    phoneNumber: string;
    effectiveIncome: number;
    registTime: string;
    userStatus: string;
    userStatusDesc: string;
    promoteByPreinstallGroupId: number;
    preinstallGroupCode: string;
    creator: number;
    createTime: string;
}



export  {baseUrl};
