import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import GoodsForm from "./goodsForm";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pdt/goods";
import goodsApi from "../../../../apis/pdt/goods";
import productApi from "../../../../apis/pdt/product";
import Details from "../../public/details/details";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";
import insPoolApi from "../../../../apis/res/insPool";


const GoodsContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos('DM_Goods_Status').then((res) => {
            if (res) {

                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Goods_Type').then((res) => {
            if (res) {

                setGoodsTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Sale_Mode').then((res) => {
            if (res) {

                setSaleModeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Display_Mode').then((res) => {
            if (res) {

                setDisplayModeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Sale_Object').then((res) => {
            if (res) {

                setSaleObjectOption(res.data)
            }
        })
        productApi.options(0).then((result) => {
            if (result) {
                setProductIdOption(result.data);
            }
        })
        insPoolApi.options().then((result) => {
            if (result) {
                setPoolOption(result.data);
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState<number>(0)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [goodsTypeOption, setGoodsTypeOption] = useState<OptionRes[]>([])
    const [saleModeOption, setSaleModeOption] = useState<OptionRes[]>([])
    const [displayModeOption, setDisplayModeOption] = useState<OptionRes[]>([])
    const [saleObjectOption, setSaleObjectOption] = useState<OptionRes[]>([])
    const [productIdOption, setProductIdOption] = useState<OptionRes[]>([])
    const [poolOption, setPoolOption] = useState<OptionRes[]>([])

    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        console.log("pageSize", pageSize)
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = goodsApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })
    }

    //处理删除
    const handleDelete = () => {
        goodsApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });
    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        goodsApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(1);
                }}>
                    编辑
                </Typography.Link>
            },
            width: 50
        },
        {
            title: '商品类型',
            dataIndex: 'goodsTypeCdDesc',
            key: 'goodsTypeCdDesc',
            ellipsis: true,
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            ellipsis: true,
        },
        {
            title: '商品编码',
            dataIndex: 'goodsCode',
            key: 'goodsCode',
            ellipsis: true,
        },
        {
            title: '关联产品',
            dataIndex: 'productIdDesc',
            key: 'productIdDesc',
            ellipsis: true,
        },
        {
            title: '商品详情',
            dataIndex: 'goodsDetail',
            key: 'goodsDetail',
            ellipsis: true,
        },
        {
            title: '广告词',
            dataIndex: 'advertWords',
            key: 'advertWords',
            ellipsis: true,
        },
        {
            title: '销售方式',
            dataIndex: 'saleModeCdDesc',
            key: 'saleModeCdDesc',
            ellipsis: true,
        },
        {
            title: '销售对象类型',
            dataIndex: 'saleObjectCdDesc',
            key: 'saleObjectCdDesc',
            ellipsis: true,
        },
        {
            title: '展示方式',
            dataIndex: 'displayModeCdDesc',
            key: 'displayModeCdDesc',
            ellipsis: true,
        },
        {
            title: '商品定价',
            dataIndex: 'priceIdDesc',
            key: 'priceIdDesc',
            ellipsis: true,
        },
        {
            title: '自动续时',
            dataIndex: 'autoRenewalDesc',
            key: 'autoRenewalDesc',
            ellipsis: true,
        },
        {
            title: '上架时间',
            dataIndex: 'listingTime',
            key: 'listingTime',
            ellipsis: true,
        },
        {
            title: '下架时间',
            dataIndex: 'delistingTime',
            key: 'delistingTime',
            ellipsis: true,
        },
        {
            title: '角色状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
            ellipsis: true,
        },
        {
            title: '是否推荐',
            dataIndex: 'recommendDesc',
            key: 'recommendDesc',
            ellipsis: true,
        },
        {
            title: '默认选中',
            dataIndex: 'defaultSelectedDesc',
            key: 'defaultSelectedDesc',
            ellipsis: true,
        },
        {
            title: '排序',
            dataIndex: 'reorder',
            key: 'reorder',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setType(0)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="goodsTypeCd" label="商品类型">
                        <Select
                            allowClear
                            style={{width: 150}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={goodsTypeOption}
                        />
                    </Form.Item>
                    <Form.Item name="goodsName" label="商品名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="goodsCode" label="商品编码">
                        <Input/>
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                goodsApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <GoodsForm
                refresh={() => {
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                statusOption={statusOption}
                displayModeOption={displayModeOption}
                goodsTypeOption={goodsTypeOption}
                saleModeOption={saleModeOption}
                saleObjectOption={saleObjectOption}
                type={type}
                productIdOption={productIdOption}
                poolOption={poolOption}>

            </GoodsForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default GoodsContent;