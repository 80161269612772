import React, {useEffect, useState} from 'react';
import {
    Button,
    ConfigProvider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Select,
    Table,
    Typography,
    Radio
} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/app/apk/notAllowInstall";
import notAllowInstallApi from "../../../../apis/app/apk/notAllowInstall";
import TextArea from "antd/es/input/TextArea";
import {ColumnsType} from "antd/es/table";
import insPoolApi from "../../../../apis/res/insPool";
import productApi from "../../../../apis/pdt/product";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface notAllowInstallFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    statusOption: OptionRes[];
    domainOption: OptionRes[];
    poolStatusOption: OptionRes[];
    productStatusOption: OptionRes[];
    open: boolean;
    type:boolean;
}

const NotAllowInstallForm: React.FC<notAllowInstallFormProps> = ({
                                                     refresh,
                                                     record,
                                                     open,
                                                     closeOpen,
                                                     type,
                                                     statusOption,
                                                     domainOption,
                                                     poolStatusOption,
                                                     productStatusOption,
                                                 }) => {
    const [form] = Form.useForm();
    const [openInsPool, setOpenInsPool] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [radioValue, setRadioValue] = useState('');
    // 表格
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });

    // 表格（产品）
    const [currentPageProduct, setCurrentPageProduct] = useState(1);
    const [totalProduct, setTotalProduct] = useState<number>()
    const [paginationLocaleProduct, setPaginationLocaleProduct] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
        if (record.insPoolNames != null){
            setRadioValue('pool')
            console.log('record.insPoolNames',record.insPoolNames)
            console.log(radioValue)
        }
        if (record.productNames != null){
            setRadioValue('product')
            console.log('record.productName',record.productNames)
            console.log(radioValue)
        }
        if (record.productNames == null && record.insPoolNames == null && record.poolStatus == 'defaultPool'){
            setRadioValue('product')
        }
        if (record.productNames == null && record.insPoolNames == null && record.poolStatus != 'defaultPool'){
            setRadioValue('pool')
        }
        if (!open){
            setRadioValue('')
        }
        console.log('radioValue', radioValue)
        console.log(record)
    }, [open]);

    useEffect(() => {
        if (openInsPool) {
            handleQuery()
        }
    }, [openInsPool])

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        notAllowInstallApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    useEffect(() => {
        console.log('当前选中的 Radio 按钮:', radioValue);
    }, [radioValue]);

    //处理取消方法
    const handleCancel = () => {
        setOpenInsPool(false)
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        notAllowInstallApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const [paginationProduct, setPaginationProduct] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    const handlePaginationChangeProduct = (page: number, pageSize: number) => {
        setCurrentPageProduct(page);
        setPaginationProduct({
            current: page,
            pageSize: pageSize
        })
    };

    const handleRadioChange = (e) => {
        if (e.target.value == "pool") {
            form.setFieldValue("poolStatus", '')
        }
        setRadioValue(e.target.value);
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination, openInsPool]);

    useEffect(() => {
        form.setFieldValue("radioGroup", radioValue)
        console.log('radioValueOption', radioValue)
    }, [radioValue])

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = insPoolApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '云手机池',
            dataIndex: 'poolName',
            key: 'poolName',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleProductQuery();
    }, [paginationProduct, openProduct]);

    //处理查询
    function handleProductQuery() {
        let body = form.getFieldsValue();
        let res = productApi.pageData(body, paginationProduct);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotalProduct(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //表格的内容
    const columnsProduct: ColumnsType<TableDataType> = [
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    const showInsPool = () => {
        setOpenInsPool(true);
    };

    const showProduct = () => {
        setOpenProduct(true);
    };

    return (
        <Modal
            title={type ? "新增应用" : "编辑应用"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 400}}
            >

                <Form.Item name="id" label="主键ID" hidden={true}>
                </Form.Item>
                <Form.Item name="packageName" label="apk包名" rules={[{required: true, message: "apk包名必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="appName" label="应用名称" rules={[{required: true, message: "应用名称必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="appDomain" label="应用类型" rules={[{required: true, message: "应用类型必填"}]}
                           initialValue={"others"}>
                    <Select
                        style={{width: 200}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={domainOption}
                    />
                </Form.Item>
                <Form.Item name="radioGroup" label="禁用的分类" rules={[{required: true, message: "禁用的分类必填"}]}>
                    <Radio.Group onChange={handleRadioChange} value={radioValue}>
                        <Radio value='pool'>手机池</Radio>
                        <Radio value='product'>产品（如果选择产品，只对默认云手机池生效）</Radio>
                    </Radio.Group>
                </Form.Item>
                {radioValue === 'pool' && (
                    <Form.Item name="poolStatus" label="云手机池禁用范围" rules={[{ required: true, message: "云手机池禁用范围必填" }]}>
                        <Select
                            style={{ width: 200 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={poolStatusOption}
                        />
                    </Form.Item>
                )}
                {radioValue === 'pool' && (
                    <Form.Item name="insPoolIdList" label="云手机池">
                        <Button type="primary" onClick={showInsPool}>
                            选择云手机池
                        </Button>
                    </Form.Item>
                )}
                {radioValue === 'pool' && (
                    <Form.Item name="tips" label="提示">
                        <Typography.Text type="secondary">全部云手机池禁用时，无需选择云手机池</Typography.Text>
                    </Form.Item>
                )}
                {radioValue === 'product' && (
                    <Form.Item name="productStatus" label="产品禁用范围" rules={[{required: true, message: "产品禁用范围必填"}]}>
                        <Select
                            style={{width: 200}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={productStatusOption}
                        />
                    </Form.Item>
                )}
                {radioValue === 'product' && (
                    <Form.Item name="productIdList" label="产品">
                        <Button type="primary" onClick={showProduct}>
                            选择产品
                        </Button>
                    </Form.Item>
                )}
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}
                           initialValue={"S01"}>
                    <Select
                        style={{width: 200}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}} maxLength={500}/>
                </Form.Item>
            </Form>
            <Modal
                okText="确认"
                cancelText="关闭弹窗"
                title="选择云手机池"
                width={800}
                open={openInsPool}
                onOk={() => {
                    if (idList.length > 0) {
                        setOpenInsPool(false);
                        setSelectedRowKeys([]);
                        setIdList([]);
                        form.setFieldValue("insPoolIdList", idList);
                    } else {
                        message.warning("请选择云手机池");
                    }

                }}
                onCancel={() => {
                    setOpenInsPool(false)
                }}
            >
            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>
                {/*表格内容*/}
                <Table
                    size="small"
                    scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "60vh"}}
                    rowSelection={{
                        ...rowSelection,
                        "selectedRowKeys": selectedRowKeys,
                    }}
                    dataSource={list}
                    columns={columns}
                    rowKey={record => record.id}
                    pagination={false}
                />

                {/*表格的分页*/}
                <div style={{ marginTop: '10px' }}>
                    <Pagination
                        size="small"
                        total={total}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultPageSize={20}
                        locale={paginationLocale}
                        onChange={handlePaginationChange}
                        showTotal={(total) => `一共 ${total} 条数据`}
                    />
                </div>
            </ConfigProvider>


            </Modal>
            <Modal
                okText="确认"
                cancelText="关闭弹窗"
                title="选择产品"
                width={800}
                open={openProduct}
                onOk={() => {
                    if (idList.length > 0) {
                        setOpenProduct(false);
                        setSelectedRowKeys([]);
                        setIdList([]);
                        form.setFieldValue("productIdList", idList);
                    } else {
                        message.warning("请选择产品");
                    }

                }}
                onCancel={() => {
                    setOpenProduct(false)
                }}
            >
                <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>
                {/*表格内容*/}
                <Table
                    size="small"
                    scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "60vh"}}
                    rowSelection={{
                        ...rowSelection,
                        "selectedRowKeys": selectedRowKeys,
                    }}
                    dataSource={list}
                    columns={columnsProduct}
                    rowKey={record => record.id}
                    pagination={false}
                />

                {/*表格的分页*/}
                <div style={{ marginTop: '10px' }}>
                    <Pagination
                        size="small"
                        total={totalProduct}
                        showSizeChanger
                        showQuickJumper
                        current={currentPageProduct}
                        defaultPageSize={20}
                        locale={paginationLocaleProduct}
                        onChange={handlePaginationChangeProduct}
                        showTotal={(total) => `一共 ${total} 条数据`}
                    />
                </div>
            </ConfigProvider>


            </Modal>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default NotAllowInstallForm;



