import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, baseUrl, TableDataType} from "../types/pdt/pdtAgentPriceRel";

const agentPriceRelApi = {

    pageData(body: PageParam) {
        let url = baseUrl;
        url += "?"
        if (body) {
            if (body.agentUserId) {
                url += 'agentUserId=' + encodeURIComponent(body.agentUserId) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/category';
        return request.put(url, body)
    },

}

export default agentPriceRelApi



