const baseUrl = "/app/apk/notAllowInstall";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    packageName:string,
    appName:string,
    statusCd:string,
    appDomain:string,
    poolStatus:string,
    productStatus:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    packageName:string,
    appName:string,
    statusDesc: string;
    insPoolNames: string;
    productNames: string;
    poolStatus: string;

}



export  {baseUrl};

