import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import propertiesApi from "../../../..//apis/sys/properties";
import {TableDataType} from "../../../../apis/types/ord/ordPromotionMonthStatistics";
import ordPromotionMonthStatisticsApi from "../../../../apis/ord/ordPromotionMonthStatistics";
import Details from "../../public/details/details";
import {filterOption} from "../../../../apis/common";

interface FormProps {
    userId: number;
}

const OrdPromotionMonthStatisticsContent: React.FC<FormProps> = ({
                                                                     userId
                                                                 }) => {

    //进入页面先查询数据
    useEffect(() => {
        form.setFieldValue("userId",userId)
        handleQuery();
        queryParams();
    }, [userId]);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [monthOptions, setMonthOptions] = useState<OptionRes[]>([])

    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        ordPromotionMonthStatisticsApi.monthList(userId).then((res) => {
            if (res) {
                setMonthOptions(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        console.log(body)
        let res = ordPromotionMonthStatisticsApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '统计周期',
            dataIndex: 'yearMonth',
            key: 'yearMonth',
            ellipsis: true,
            width: 200
        },
        {
            title: '推广用户数',
            dataIndex: 'promotionUsers',
            key: 'promotionUsers',
            ellipsis: true,
            width: 200
        },
        {
            title: '推广金额（元）',
            dataIndex: 'promotionAmount',
            key: 'promotionAmount',
            ellipsis: true,
            width: 200
        },
        {
            title: '收益（元）',
            dataIndex: 'commission',
            key: 'commission',
            ellipsis: true,
            width: 200
        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="userId" label="用户id" hidden={true}>
                    </Form.Item>
                    <Form.Item name="yearMonth" label="统计周期">
                        <Select
                            style={{width: 200}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={monthOptions}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button onClick={() => {
                                const body = {"userId":userId};
                                form.resetFields();
                                form.setFieldValue("userId",userId)
                                ordPromotionMonthStatisticsApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                    dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{textAlign: 'right', padding: '10px'}}>
                <Pagination size="small"
                            style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default OrdPromotionMonthStatisticsContent;