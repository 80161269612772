import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";
import {PageParam, baseUrl, TableDataType} from "../types/pdt/agentGoodsPrice";

const agentGoodsPriceApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.goodsName) {
                url += 'goodsName=' + encodeURIComponent(body.goodsName) + "&";
            }
            if (body.productId) {
                url += 'productId=' + body.productId + "&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "/del?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/category';
        return request.put(url, body)
    },
    //查询列表
    options(goodsId:number,redeemType:string) {
        let url = baseUrl;
        url += "/options"
        let body = {"goodsId": goodsId,"redeemType":redeemType}
        return request.post<OptionRes[]>(url, body)
    },
    derive(body: PageParam) {
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url, "代理商商品价格表", body)
    },

}

export default agentGoodsPriceApi



