const baseUrl = "/ord/promotion/statistics";
/** 获取列表-参数 */
export interface PageParam {
    subscriberCode: string;
    phoneNumber: string;
    promotionCode: string;
    payeeAlipayAccount: string;
    userStatus: string;
    sortBy: string;
    ascending: boolean;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    userId: number;
    totalPromotionUsers: number;
    totalPromotionAmount: number;
    currentMonthCommission: number;
    lastMonthCommission: number;
    totalCommission: number;
    creator: number;
    modifier: number;
    createTime: string;
    modifyTime: string;
    softDel: number;
    enableStatus: string;
    promotionCode: string;
    subscriberCode: string;
    nickName: string;
    phoneNumber: string;
    payeeName: string;
    payeeAlipayAccount: string;
    userStatus: string;
    userStatusDesc: string;
    promotionOpenTime: string;
}



export  {baseUrl};
