import React, {useEffect} from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select} from 'antd';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/sys/staff";
import staffApi from "../../../../apis/sys/staff";
import userApi from "../../../../apis/sys/user";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    type: boolean;
    record: TableDataType;
    sexOption: OptionRes[];
    certOption: OptionRes[];
    organOption: OptionRes[];
    statusOption: OptionRes[];
    open: boolean;
    model: string;
}


const StaffFrom: React.FC<StaffFromProps> = ({
                                                 model,
                                                 refresh,
                                                 type,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 sexOption,
                                                 certOption,
                                                 organOption,
                                                 statusOption
                                             }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                ...record,
                registDate: dayjs(record.registDate),
            })
            handleQuery();
        }

    }, [open]);

    function handleQuery() {
        if (record.id) {
            userApi.getById(record.id).then((reset) => {
                if (reset.data) {
                    form.setFieldsValue({
                        userId: reset.data.id,
                        userAcctNo: reset.data.acctNo,
                        phoneNumber: reset.data.phoneNumber,
                        userAcctPwd: '',
                        userPwd: '',
                        userEffTime: dayjs(reset.data.effTime),
                        userExpTime: dayjs(reset.data.expTime),
                        userStatusCd: reset.data.statusCd,
                        userNotes: reset.data.notes,
                    })
                }
            })
        }
    }

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            registDate: values.registDate ? dayjs(values.registDate).format('YYYY-MM-DD') : null,
            userEffTime: values.userEffTime ? dayjs(values.userEffTime).format('YYYY-MM-DD HH:mm:ss') : null,
            userExpTime: values.userExpTime ? dayjs(values.userExpTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        staffApi.create(valueJSOn).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        form.setFieldsValue({})
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            registDate: values.registDate ? dayjs(values.registDate).format('YYYY-MM-DD') : null,
            userEffTime: values.userEffTime ? dayjs(values.userEffTime).format('YYYY-MM-DD HH:mm:ss') : null,
            userExpTime: values.userExpTime ? dayjs(values.userExpTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        staffApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    function onblur() {
        open = true;
    }


    return (
        <Modal
            title="用户信息"
            open={open}
            width={1200}
            onCancel={handleCancel}
            onOk={handleOK}
            okText={"保存"}
            cancelText={"取消"}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onBlur={onblur}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={8}>
                        <Form.Item name="staffNo" label="人员工号" rules={[{required: true, message: "人员工号必填"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="staffName" label="人员姓名"
                                   rules={[{required: true, message: "人员姓名必填"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="sexTypeCd" label="性别" rules={[{required: true, message: "性别必填"}]}>
                            <Select
                                allowClear
                                style={{width: 256}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={sexOption}
                            />
                        </Form.Item>
                    </Col>
                    {/*<Col span={6}>*/}
                    {/*    <Form.Item name="certTypeCd" label="证件类型"*/}
                    {/*               rules={[{required: true, message: "证件类型必填"}]}>*/}
                    {/*        <Select*/}
                    {/*            allowClear*/}
                    {/*            style={{width: 256}}*/}
                    {/*            showSearch*/}
                    {/*            optionFilterProp="children"*/}
                    {/*            filterOption={filterOption}*/}
                    {/*            options={certOption}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    {/*<Col span={6}>*/}
                    {/*    <Form.Item name="certNo" label="证件号码" rules={[{required: true, message: "证件号码必填"}]}>*/}
                    {/*        <Input width={256}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <Form.Item name="organId" label="归属组织" rules={[{required: true, message: "归属组织必填"}]}>
                            <Select
                                allowClear
                                style={{width: 256}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={organOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="registDate" label="注册日期"
                                   rules={[{required: true, message: "注册日期必填"}]}>
                            <DatePicker style={{width: 256}} placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                allowClear
                                style={{width: 256}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={statusOption}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="notes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div>
                                <Divider style={{height:1,marginTop:10,marginBottom:0}} />

                    <h3>账号信息</h3>
                    <Form.Item name="userId" label="id值" hidden={true}>
                    </Form.Item>
                    <Row>
                        <Col span={type?8:6}>
                            <Form.Item
                                name="userAcctNo"
                                label="账号"
                                rules={!type?[]:[
                                    {required: true, message: '账号必填'},
                                    {
                                        pattern: /^[A-Za-z0-9!@#$%^&*()_+\-=]{6,32}$/,
                                        message: '请确保账号只包含字母、数字、特殊符号，并且长度为6-32位'
                                    },
                                ]}
                            >
                                <Input style={{width: type?256:192}} disabled={!type}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="phoneNumber"
                                label="手机号码"
                                rules={[
                                    {required: false},
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: '请确保输入的手机号码是正确格式（11位数字，且数字1开头）'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={type?8:6}>
                            <Form.Item
                                name="userAcctPwd"
                                label="密码"
                                rules={[
                                    {required: type, message: '密码必填'},
                                    {
                                        validator: async (_, value) => {
                                            if (value) {
                                                const pattern = /^[A-Za-z0-9!@#$%^&*()_+\-=]{8,16}$/;
                                                if (pattern.test(value)) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject('请确保密码只包含字母、数字、特殊符号，并且长度为8-16位');
                                                }
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input.Password visibilityToggle={false} style={{width: type?256:192}}/>
                            </Form.Item>
                        </Col>
                        <Col span={type?0:6} hidden={type}>
                            <Form.Item
                                label="重复密码"
                                name="userPwd"
                                dependencies={['userAcctPwd']}
                                rules={[
                                    {
                                        required: model === 'create', message: '重复密码必填'
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            const acctPwd = getFieldValue('userAcctPwd');
                                            if (model === 'edit' && acctPwd && !value) {
                                                return Promise.reject(new Error('重复密码必填'));
                                            }
                                            if (!value || acctPwd === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('您输入的新密码不匹配！'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password visibilityToggle={false} style={{width: type?256:192}}/>
                            </Form.Item>
                        </Col>
                        <Col span={type?8:6}>
                            <Form.Item name="userEffTime" label="生效时间"
                                       rules={[{required: true, message: "生效时间必填"}]}>
                                <DatePicker showTime placeholder={''} style={{width: type?256:192}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={type?8:6}>
                            <Form.Item name="userExpTime" label="失效时间"
                                       rules={[{required: true, message: "失效时间必填"}]}>
                                <DatePicker showTime placeholder={''} style={{width: type?256:192}}/>
                            </Form.Item>
                        </Col>
                        <Col span={type?8:6}>
                            <Form.Item name="userStatusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                                <Select
                                    style={{width: type?256:192}}
                                    allowClear
                                >
                                    <Option value="S01">有效</Option>
                                    <Option value="S02">失效</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={type?8:6}>
                            <Form.Item name="userNotes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                                <TextArea rows={1} style={{width: type?256:192}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default StaffFrom;



