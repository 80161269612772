import React, {useEffect, useState} from 'react';
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Upload, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../../apis/types/common";
import {TableDataType} from "../../../../../apis/types/mkt/act/goods/goodsAct";
import goodsActApi from "../../../../../apis/mkt/act/goods/goodsAct";
import {filterOption} from "../../../../../apis/common";
import {domain, imgIp} from "../../../../../components/constantConfig";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../../utils";
import productApi from "../../../../../apis/pdt/product";
import goodsApi from "../../../../../apis/pdt/goods";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ActGoodsFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: Number;
    actId: Number;
    setActGoodsList: (list:any) => void;
    setList: (list:any) => void;
    list: TableDataType[];


}


const ActGoodsForm: React.FC<ActGoodsFromProps> = ({
                                                       model,
                                                       refresh,
                                                       record,
                                                       open,
                                                       closeOpen,
                                                       actId,
                                                       setActGoodsList,
                                                       setList,
                                                       list


                                                   }) => {
    const [productOption, setProductOption] = useState<OptionRes[]>([])
    const [goodsOption, setGoodsOption] = useState<OptionRes[]>([])
    const [pcIconUrl, setPcIconUrl] = useState('')
    const [appIconUrl, setAppIconUrl] = useState('')
    const [productName, setProductName] = useState('')
    const [goodsName, setGoodsName] = useState('')
    const [enableStatusDesc, setEnableStatusDesc] = useState('')
    const goodsTypes = ["G01","G02","G03","G04","G05","G06"]

    const [form] = Form.useForm();
    const [id, setId] = useState(0); // 初始化 ID 为 0

    const incrementId = () => {
        setId((prevId) => prevId + 1); // 自增 ID
    };
    // 编辑的时候回显数据
    useEffect(() => {
        console.log("actId", actId)
        productApi.options(0).then((res) => {
            if (res) {
                setProductOption(res.data)
            }
        })
        goodsApi.options(null,null,undefined).then((res) => {
            if (res) {
                setGoodsOption(res.data)
            }
        })
        if (open && Object.keys(record).length > 0) {
            let productId = record.productId;

            // 设置一个延时，确保在 goodsApi.options({}) 之后执行
            setTimeout(() => {
                goodsApi.options(productId,null,undefined).then((res) => {
                    if (res) {
                        setGoodsOption(res.data);
                    }
                });
                console.log("actIdtttt", record);
                console.log("goodsOption", goodsOption);
                form.setFieldsValue({
                    ...record
                });
                // 编辑时的数据回显
                setProductName(record.productName)
                setGoodsName(record.goodsName)
                setEnableStatusDesc(record.enableStatusDesc)
            }, 0); // 延时0毫秒，只是为了让这个请求排在其他请求之后执行
        }

    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        incrementId();
        values = {
            ...values,
            "id":id,
            "actId": actId,
            "pcIcon": pcIconUrl,
            "appIcon": appIconUrl,
            "productName":productName,
            "goodsName":goodsName,
            "enableStatusDesc":enableStatusDesc

        };
        setList([...list, values]);
        setActGoodsList([...list, values]);
        handleCancel();

    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
        setPcIconUrl('');
        setAppIconUrl('');
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    //处理新增的情况
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            "actId": actId,
            "productName":productName,
            "goodsName":goodsName,
            "enableStatusDesc":enableStatusDesc

        };
        if (pcIconUrl) {
            values.pcIcon = pcIconUrl;
        }
        if (appIconUrl) {
            values.appIcon = appIconUrl;
        }
        console.log("handleUpdate",values)
        console.log("list",list)
        const newList = list.filter(item => item.id!==values.id);
        console.log("newList",newList)

        setList([...newList, values]);
        setActGoodsList([...newList, values]);
        handleCancel();


    }

    const pcImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setPcIconUrl(urlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setPcIconUrl(pcIconUrl)
        },

    };

    const appImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setAppIconUrl(urlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setAppIconUrl(appIconUrl)
        },

    };

    return (
        <Modal
            title={model == 1 ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="productId" label="产品名称"
                                   rules={[{required: true, message: "产品名称必填"}]}>
                            <Select

                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                onClick={() => {
                                    let productId = form.getFieldValue("productId");
                                    if (productId) {
                                        console.log("productId", productId)
                                        goodsApi.options(productId,null,undefined).then((res) => {
                                            if (res) {
                                                setGoodsOption(res.data)
                                            }
                                        })
                                    }

                                }}
                                onChange={( value) => {
                                    const selectedOption = productOption.find(option => option.value === value);
                                    if (selectedOption) {
                                        const label = selectedOption.label; // 获取 label 数据
                                        setProductName(label); // 更新状态
                                    }
                                }}
                                filterOption={filterOption}
                                options={productOption}
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="goodsId" label="商品" rules={[{required: true, message: "商品必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                onChange={( value) => {
                                    const selectedOption = goodsOption.find(option => option.value === value);
                                    if (selectedOption) {
                                        const label = selectedOption.label; // 获取 label 数据
                                        setGoodsName(label); // 更新状态
                                    }
                                }}
                                options={goodsOption}
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="actPrice" label="活动价(分)"
                                   rules={[{required: true, message: "活动价必填"},{type:'number',max:9999999,message:"活动价不可超过9999999"}]}>
                            <InputNumber min={0}  max={2147483647} style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="enableStatus" label="启用状态"
                                   rules={[{required: true, message: "启用状态必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: 0, label: '禁用'}, {value: 1, label: '启用'}]}
                                onChange={(value) => {
                                    // 通过 options 查找对应的 label
                                    const selectedOption = [{value: 0, label: '禁用'}, {value: 1, label: '启用'}].find(option => option.value === value);
                                    setEnableStatusDesc(selectedOption ? selectedOption.label : null);
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="notes" label="备注">
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="goodsIntroduce" label="商品介绍"
                                   rules={[{required: true, message: "商品介绍必填"}]}>
                            <TextArea rows={2} style={{width: 200}} maxLength={52}/>
                        </Form.Item>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="pcIcon" label="pc应用角标">
                            {pcIconUrl !== '' ? <Image src={imgIp + pcIconUrl} width={100}/> :
                                <Image src={imgIp + record.pcIcon} width={100}/>}
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="pcIconUpload" label="pcIcon上传">
                            <Upload {...pcImageprops} >
                                <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="appIcon" label="app应用角标">
                            {appIconUrl !== '' ? <Image src={imgIp + appIconUrl} width={100}/> :
                                <Image src={imgIp + record.appIcon} width={100}/>}
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="appIconUpload" label="appIcon上传">
                            <Upload {...appImageprops} >
                                <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                </div>
            </Form>


        </Modal>
    );
};

export default ActGoodsForm;




