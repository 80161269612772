import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Popconfirm,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../mkt/redeemCode/details";
import agentPriceRelApi from "../../../../apis/pdt/pdtAgentPriceRel";
import {TableDataType} from "../../../../apis/types/pdt/pdtAgentPriceRel";
import PdtAgentPriceRelForm from "./PdtAgentPriceRelForm";

interface FormProps {
    agentUserId: number;
    updateRelList: (newRelList) => void;
    date:number
}

const SubAgentUserContent: React.FC<FormProps> = ({
                                                      agentUserId,
                                                      updateRelList,
    date
                                                  }) => {
    //进入页面先查询数据
    useEffect(() => {
        form.setFieldValue("agentUserId", agentUserId)
        setList([])
        handleQuery();
        updateRelList(list)
    }, [date]);

    // 子组件通过这个函数传递新数据
    const addItem = (newItem) => {
        // 1. 检查是否存在相同 id 的项，若存在则更新
        const indexById = list.findIndex(item => item.id === newItem.id);
        if (indexById !== -1) {
            // 更新 list 中的对象
            const newList=[...list];
            newList[indexById]=newItem
            setList([...newList])
            message.success("修改成功");
            return;
        }

        // 2. 如果没有相同 id，检查是否有完全相同的 goodsId, priceId, redeemType
        const indexByDetails = list.findIndex(item =>
            item.goodsId === newItem.goodsId &&
            item.redeemType === newItem.redeemType
        );

        if (indexByDetails > -1) {
            // 如果找到了相同的商品、价格和兑换类型的项，则抛出错误
            message.error("新增失败已存在相同的商品、价格和兑换类型的项，不能添加！",3);
        } else {
            // 3. 如果两个条件都不满足，添加 newItem 到 list
            setList([...list, newItem])
            message.success("新增成功");
        }
        updateRelList(list)
    };

    //定义用到的useState，用来绑定数据
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState<number>(0)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    //list变更时同步到父级表单
    useEffect(() => {
        updateRelList(list)
    }, [list]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body={...body,"agentUserId": agentUserId}
        let res = agentPriceRelApi.pageData(body);
        res.then((result) => {
            if (result) {
                setList(result.data)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })
    }

    //处理删除
    const handleDelete = () => {
        const newList = list.filter(item => !idList.includes(item.id));
        setList(newList)
        //接口成功后重置idlist
        setIdList([])
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(1);
                }}>
                    编辑
                </Typography.Link>
            },
            width: 60
        },
        {
            title: '兑换码商品',
            dataIndex: 'goodsName',
            key: 'goodsName',
            ellipsis: true,
            width: 200
        },
        {
            title: '兑换类型',
            dataIndex: 'redeemTypeDesc',
            key: 'redeemTypeDesc',
            ellipsis: true,
            width: 200
        },
        {
            title: '价格，单位分',
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
            width: 200
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            ellipsis: true,
            width: 200
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setType(0)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
            </div>
            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, y: "30vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>


            {/*新增和编辑的form表单，子组件*/}
            <PdtAgentPriceRelForm
                model={type}
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                addItem={(newItem) => {
                    addItem(newItem)
                }}
                closeOpen={() => {
                    setOpen(false);
                }}
                open={open}
                agentUserId={agentUserId}
            ></PdtAgentPriceRelForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default SubAgentUserContent;