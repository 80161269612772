const baseUrl = "/pdt/agent/price/rel";
/** 获取列表-参数 */
export interface PageParam {
    agentUserId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    agentUserId: number;
    goodsId: number;
    priceId: number;
    goodsName: string;
    price: number;
    redeemType: string;
    quantity: number;
    createTime: string;
    creater: number;
    modifier: number;
    modifyTime: string;
    enableStatus: number;
    softDel: number;
    notes: string;
}

export  {baseUrl};