import React from "react";
import VueIframe from "../../../../components/VueIframe";
import { vueIframeDomain } from "../../../../components/constantConfig";

const VueIframeDemo = () => {
  return (
    <VueIframe idKey={"actRewardManage"} src={vueIframeDomain + "/market/activityReward"} />
  );
};

export default VueIframeDemo;
