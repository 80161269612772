import React, {useEffect, useState} from 'react';
import {Col, Form, Input, message, Modal, Row, Select} from 'antd';
import subAgentUserApi from "../../../../apis/sub/SubAgentUser";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/sub/SubAgentUser";
import TextArea from "antd/es/input/TextArea";
import SubAgentUserContent from "../../pdt/agentPriceRel/PdtAgentPriceRelContent";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface agentGoodsPriceFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    poolOption: OptionRes[];
    model: number
}


const AgentGoodsPriceForm: React.FC<agentGoodsPriceFormProps> = ({
                                                                     refresh,
                                                                     record,
                                                                     open,
                                                                     closeOpen,
                                                                     poolOption,
                                                                     model
                                                                 }) => {
    const [agentUserForm] = Form.useForm();
    const [num, setNum] = useState<number>(0);

    useEffect(() => {
        agentUserForm.resetFields()
        setRelList([])
        if (model == 1) {
            agentUserForm.setFieldsValue({
                ...record,
                pwd: ''
            });
        }
        setNum(num+1)
    }, [open])

    //处理新增方法
    const handleCreate = () => {
        let values = agentUserForm.getFieldsValue();
        values = {...values,"agentPriceRelReqVoList":relList}
        subAgentUserApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("新增成功");
            }
        });
    };


    const [relList, setRelList] = useState<[]>([]);

    function updateRelList(newRelList:[]) {
        setRelList(newRelList)
    }

    //处理取消方法
    const handleCancel = () => {
        agentUserForm.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = agentUserForm.getFieldsValue();
        values = {...values,"agentPriceRelReqVoList":relList}
        let valueJSOn = JSON.stringify(values);
        subAgentUserApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("修改成功");
            }
        });
    }


    //处理ok按钮，
    function handleOK() {
        agentUserForm.validateFields().then(() => {
            const values = agentUserForm.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1200}
        >
            <Form
                {...layout}
                form={agentUserForm}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="createTime" label="创建时间" hidden={true}>
                </Form.Item>
                <Form.Item name="creater" label="创建人" hidden={true}>
                </Form.Item>
                <Form.Item name="modifyTime" label="修改时间" hidden={true}>
                </Form.Item>
                <Form.Item name="modifier" label="修改人" hidden={true}>
                </Form.Item>
                <Form.Item name="enableStatus" label="启用状态" hidden={true}>
                </Form.Item>
                <Form.Item name="softDel" label="是否删除" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item name="userName" label="代理商名称"
                                   rules={[{required: true, message: "代理商名称不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="mobile" label="手机号码"
                                   rules={[{required: true, message: "手机号码不能为空"},
                                       {required: false},
                                       {
                                           pattern: /^1\d{10}$/,
                                           message: '请确保输入的手机号码是正确格式（11位数字，且数字1开头）'
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="pwd"
                            label="密码"
                            rules={[
                                {required: model == 0, message: '密码必填'},
                                {
                                    validator: async (_, value) => {
                                        if (value) {
                                            const pattern = /^[A-Za-z0-9!@#$%^&*()_+\-=]{8,16}$/;
                                            if (pattern.test(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('请确保密码只包含字母、数字、特殊符号，并且长度为8-16位');
                                            }
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input.Password visibilityToggle={false}
                                            style={{width: model == 0 ? 256 : 192}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            hidden={model === 0}
                            label="重复密码"
                            name="confirmPwd"
                            dependencies={['confirmPwd']}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const acctPwd = getFieldValue('pwd');
                                        if (model === 1 && acctPwd && !value) {
                                            return Promise.reject(new Error('重复密码必填'));
                                        }
                                        if (!value || acctPwd === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('您输入的新密码不匹配！'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password visibilityToggle={false} style={{width: model == 0 ? 256 : 192}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="userCode" label="代理商编码"
                                   rules={[{required: true, message: "代理商编码不能为空"},{min:3,max:12,message:"代理商编码长度不能小于3或大于12"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="notes" label="备注">
                            <TextArea rows={1} style={{width: 200}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <SubAgentUserContent agentUserId={record.id} updateRelList={updateRelList} date={num}></SubAgentUserContent>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default AgentGoodsPriceForm;



