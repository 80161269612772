import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";
import goodsApi from "../../../../../apis/pdt/goods";
import propertiesApi from "../../../../../apis/sys/properties";
import {filterOption} from "../../../../../apis/common";
import Details from "../../../mkt/redeemCode/details";
import {TableDataType} from "../../../../../apis/types/ord/agentOrder";
import {OptionRes} from "../../../../../apis/types/common";
import agentOrderApi from "../../../../../apis/ord/agentOrder";


const {RangePicker} = DatePicker;
const AgentOrderContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        goodsApi.options(null,'G07').then((res) => {
            if (res) {
                setGoodsOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Redeem_Type').then((res) => {
            if (res) {
                setRedeemTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Pay_Status').then((res) => {
            if (res) {
                setPayStatusOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [payStatusOption, setPayStatusOption] = useState<OptionRes[]>([])
    const [goodsOption, setGoodsOption] = useState<OptionRes[]>([])
    const [redeemTypeOption, setRedeemTypeOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            createTimeBegin: body.createTimeBegin ? dayjs(body.createTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            createTimeEnd: body.createTimeEnd ? dayjs(body.createTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
        };
        console.log(body)
        let res = agentOrderApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })
    }

    const [messageApi] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            createTimeBegin: body.createTimeBegin ? dayjs(body.createTimeBegin).format('YYYY-MM-DD 00:00:00') : null,
            createTimeEnd: body.createTimeEnd ? dayjs(body.createTimeEnd).format('YYYY-MM-DD 00:00:00') : null,
        };
        agentOrderApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '订单编号',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 130
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            width: 130
        },
        {
            title: '商品',
            dataIndex: 'goodsName',
            key: 'goodsName',
            ellipsis: true,
            width: 130
        },
        {
            title: '兑换类型',
            dataIndex: 'redeemTypeDesc',
            key: 'redeemTypeDesc',
            ellipsis: true,
            width: 130
        },
        {
            title: '发起IP',
            dataIndex: 'sourceIp',
            key: 'sourceIp',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付方式',
            dataIndex: 'payModeName',
            key: 'payModeName',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付金额(分)',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            ellipsis: true,
            width: 130
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付时间',
            dataIndex: 'payTime',
            key: 'payTime',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付状态',
            dataIndex: 'payStatusCdDesc',
            key: 'payStatusCdDesc',
            ellipsis: true,
            width: 130
        },
        {
            title: '订单流水号',
            dataIndex: 'orderCode',
            key: 'orderCode',
            ellipsis: true,
            width: 130
        },
        {
            title: '批次号',
            dataIndex: 'batchNo',
            key: 'batchNo',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付单流水号',
            dataIndex: 'paymentCode',
            key: 'paymentCode',
            ellipsis: true,
            width: 130
        },
        {
            title: '支付序列号',
            dataIndex: 'paymentSerial',
            key: 'paymentSerial',
            ellipsis: true,
            width: 130
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            // setIdList(Array.from(newSet) as Number[]);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    return (

        <div>

            {/*查询的表单*/}
            <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                  style={{maxWidth: 3000}}
                  size={"small"}
                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >
                <Form.Item style={{margin: 5}} name="id" label="订单编号">
                    <Input style={{width: 150}} type="number"/>
                </Form.Item>
                <Form.Item style={{margin: 5}} name="phoneNumber" label="手机号码">
                    <Input style={{width: 150}}/>
                </Form.Item>
                <Form.Item style={{margin: 5}} name="goodsId" label="商品">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={goodsOption}
                    />
                </Form.Item>
                <Form.Item style={{margin: 5}} name="redeemType" label="兑换类型">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={redeemTypeOption}
                    />
                </Form.Item>
                <Form.Item style={{margin: 5}} name="payStatusCd" label="支付状态">
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={payStatusOption}
                    />
                </Form.Item>
                <Form.Item style={{margin: 5}} name="paymentCode" label="支付单流水号">
                    <Input/>
                </Form.Item>
                <Form.Item name="createTimeBegin" label="订单创建时间开始" hidden={true}></Form.Item>
                <Form.Item name="createTimeEnd" label="订单创建时间结束" hidden={true}></Form.Item>
                <Form.Item style={{margin: 5}} name="createTime" label="订单创建时间">
                    <RangePicker
                        style={{width: 300}}
                        placeholder={['', '']}
                        onChange={(value) => {
                            if (value == null) {
                                form.setFieldValue("createTimeBegin", null);
                                form.setFieldValue("createTimeEnd", null);
                            } else {
                                if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("createTimeBegin", date[0]);
                                        form.setFieldValue("createTimeEnd", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                    console.log(form.getFieldsValue())
                                }
                            }
                        }}></RangePicker>
                </Form.Item>
                <div style={{display: 'flex', alignItems: 'center', padding: 0}}>
                    <Form.Item >
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                agentOrderApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button>
                            <Popconfirm
                                title="是否确认导出筛选的数据？"
                                onConfirm={confirmDerive}
                                onCancel={cancelDerive}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="primary" danger style={{marginRight: '25px'}}>
                                    导出
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Form.Item>
                </div>
            </Form>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "68vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default AgentOrderContent;