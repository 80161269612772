import { getToken, removeToken } from "../utils";
import React, { useEffect, useState } from "react";
import { vueIframeDomain } from "./constantConfig";

interface OptionModel {
  src: string;
  idKey: string;
  origin?: string;
  msgCallback?: (data: any) => void;
}

const VueIframe = (option: OptionModel) => {
  const { src, idKey, msgCallback, origin = vueIframeDomain } = option;
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    // 初始化Iframe的src
    setIframeSrc(src);
  }, [src]);

  // 获取iframe实例
  const getIframe = () => {
    return document.getElementById(idKey) as HTMLIFrameElement;
  };
  // 发送消息
  const sendMessage = (message) => {
    const iframe = getIframe();
    message && iframe?.contentWindow?.postMessage?.(message, origin);
  };
  // 监听来自vue页面的消息
  const onMessage = (event) => {
    const _origin = origin.replace('/vue/#', "");
    // 判断数据源是否匹配
    if (event?.origin === _origin) {
      const { type, data } = event.data || {};
      if (!type || !data) return;
      // 更新页面的数据
      msgCallback?.(data);
      switch (type) {
        // 子页面发来的加载状态消息
        case "childStatus":
          // 加载完毕，发送token
          data === "isReady" &&
            sendMessage({ type: "token", data: getToken() });
          break;
        // token过期
        case "tokenExpired":
          removeToken();
          window.location.assign(`/login?msg=${data || ""}`);
          break;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <iframe
      id={idKey}
      src={iframeSrc}
      style={{ width: "100%", height: "85vh", border: "none" }}
    />
  );
};

export default VueIframe;
