const baseUrl = "/mkt/act";
/** 获取产品申请列表-参数 */
export interface PageParam {
    actType:string,
    actTypeList?:string,
    startStartTime:string,
    endStartTime:string,
    startEndTime:string,
    endEndTime:string,
    actCode:string,
    enableStatus:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    actName: string;
    actTypeDesc: string;
    actType: string;
    startTime: string;
    endTime: string;
    actCode: string;
    sort: string;
    notes: string;
    appIcon: string;
    pcIcon: string;
    enableStatusDesc: string;
    createTime: string;
    updateTime: string;
    reorder: number;

}



export  {baseUrl};

