import React, {useEffect, useState} from 'react';
import {
    Button, Col, DatePicker, Divider,
    Form,
    Image,
    Input, InputNumber,
    message,
    Modal, Row,
    Select, Tooltip,
    Upload,
    UploadProps
} from 'antd';
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/mkt/advert/advert";
import advertApi from "../../../../apis/mkt/advert/advert";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../components/constantConfig";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import moment from "moment";
import {ExclamationCircleFilled} from '@ant-design/icons';

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
};


interface AdvertFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    model: string;
    open: boolean;
    adShowPositionOption: OptionRes[];
    advertInteractTypeOption: OptionRes[];
    appPageOption: OptionRes[];
    maxTimePerDayOption: OptionRes[];
}


const AdvertForm: React.FC<AdvertFormProps> = ({
                                                             refresh,
                                                             record,
                                                             open,
                                                             model,
                                                             closeOpen,
                                                   adShowPositionOption,
                                                   advertInteractTypeOption,
                                                   appPageOption,
                                                   maxTimePerDayOption
                                                         }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        setSelectAdShowPosition("");
        setSelectAdvertInteractTypeOption("");
        setPcImgPath("");
        setOldPcImgPath("");
        setAppImgPath("");
        setOldAppImgPath("");
        console.log("record", record);
        if (open && record) {
            form.setFieldsValue({
                ...record,
                enableStatus: record.enableStatus !== null && record.enableStatus !== undefined
                        ? record.enableStatus.toString() : '0',
                maxTimePerDay: record.maxTimePerDay !== null && record.maxTimePerDay !== undefined
                    ? record.maxTimePerDay.toString() : null,
                startTime: record.startTime ? dayjs(record.startTime) : null,
                endTime: record.endTime ? dayjs(record.endTime) : null,
            });
            setSelectAdShowPosition(record.showPosition);
            setSelectAdvertInteractTypeOption(record.interactType);
            setPcImgPath(record.pcImgPath);
            setOldPcImgPath(record.pcImgPath);
            setAppImgPath(record.appImgPath);
            setOldAppImgPath(record.appImgPath);
        }
    }, [open]);




    // 自定义校验规则
    const validateExpTime = (_, value) => {
        const startTime = form.getFieldValue('startTime'); // 获取开始时间的值
        if (value && startTime && !value.isAfter(startTime)) {
            return Promise.reject('广告开始时间不能小于广告结束时间');
        }
        const currentTime = moment(); // 获取当前时间
        // 请求失败 广告结束时间不能小于当前时间
        if (value && !value.isAfter(currentTime)) {
            return Promise.reject('广告结束时间不能小于当前时间');
        }
        return Promise.resolve();
    };

    //处理新增方法
    const requestPayload = () => {
        const formValues = form.getFieldsValue();
        let req = {
            id: formValues.id,
            adTitle: formValues.adTitle,
            enableStatus: formValues.enableStatus,
            reorder: formValues.reorder,
            startTime: formValues.startTime ? dayjs(formValues.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: formValues.endTime ? dayjs(formValues.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
            showPosition: formValues.showPosition,
            appImgPath: "",
            pcImgPath: "",
            maxTimePerDay: 0,
            jumpLink: "",
            interactType: "",
            jumpPage: "",
        };

        switch (selectAdShowPosition) {
            case 'bootScreen':
                req.appImgPath = appImgPath;
                req.interactType = "display";
                req.maxTimePerDay = 0;
                break;
            case 'officialWebsite':
                req.pcImgPath = pcImgPath;
                req.maxTimePerDay = formValues.maxTimePerDay;
                req.jumpLink = formValues.jumpLink;
                req.interactType = "jumpLink";
                break;
            case 'homePage':
                req.appImgPath = appImgPath;
                req.pcImgPath = pcImgPath;
                req.maxTimePerDay = formValues.maxTimePerDay;
                req.interactType = formValues.interactType;
                req[selectAdvertInteractTypeOption === 'jumpLink' ? 'jumpLink' : 'jumpPage'] = formValues[selectAdvertInteractTypeOption === 'jumpLink' ? 'jumpLink' : 'jumpPage'];
                break;
            default:
                break;
        }

        return req;
    };

    const handleCreate = () => {
        const req = requestPayload();

        advertApi.create(JSON.stringify(req)).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };


    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = requestPayload();
        advertApi.update(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.setFieldValue("pcImgPath",pcImgPath);
        form.setFieldValue("appImgPath",appImgPath);
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    function onblur() {
        open = true;
    }

    const [pcImgPath, setPcImgPath] = useState(record.pcImgPath)
    const [oldPcImgPath, setOldPcImgPath] = useState(record.pcImgPath);

    const [appImgPath, setAppImgPath] = useState(record.appImgPath)
    const [oldAppImgPath, setOldAppImgPath] = useState(record.appImgPath);


    const [selectAdShowPosition, setSelectAdShowPosition] = useState('')
    const [selectAdvertInteractTypeOption, setSelectAdvertInteractTypeOption] = useState(record.interactType)


    const pcImgPathProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setPcImgPath(urlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.webp', '.png'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('jpg、png、webp格式图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 2;
            if (!isLt20M) {
                message.error('图片大小不能超过2MB');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setPcImgPath(oldPcImgPath)
        },

    };

    const appImgPathProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setAppImgPath(urlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.webp', '.png'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('jpg、png、webp格式图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 2;
            if (!isLt20M) {
                message.error('图片大小不能超过2MB');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setAppImgPath(oldAppImgPath)
        },

    };

    return (
        <Modal
            title={model === 'create'? "新增广告" : "修改广告"}
            open={open}
            width={1600}
            onCancel={handleCancel}
            onOk={handleOK}
            okText={"保存"}
            cancelText={"取消"}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onBlur={onblur}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item name="adTitle" label="广告名称" rules={[{required: true, message: "广告名称必填"}, {max: 100, message: '广告名称长度不能大于100'}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="enableStatus" label="启用状态">
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '0', label: '禁用'}, {value: '1', label: '启用'}]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item style={{margin: 5}} name="startTime" label="开始时间"
                                   rules={[{required: true, message: "开始时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width:250}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item style={{margin: 5}} name="endTime" label="结束时间"
                                   rules={[{required: true, message: "结束时间必填"},
                                       { validator: validateExpTime}] // 使用自定义校验规则
                                   }
                        >
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width:250}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col span={6}>
                        <Form.Item name="reorder" label="排序">
                            <InputNumber style={{width: 250}} min={0} max={32767} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Item
                                name="showPosition"
                                label="展示位置"
                                rules={[{ required: true, message: "展示位置必填" }]}
                                style={{ marginBottom: 0 }} // 防止额外的下边距
                            >
                                <Select
                                    style={{ width: 250 }}
                                    disabled={model === 'edit'} // 当 编辑的时候禁用选择框
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={adShowPositionOption}
                                    onChange={(value) => {
                                        setSelectAdShowPosition(value);
                                    }}
                                />
                            </Form.Item>
                            <div style={{ marginLeft: 35 }}> {/* 包裹Tooltip的div */}
                                <Tooltip
                                    title={
                                        <>
                                            开屏：广告样式为开屏广告<br />
                                            首页：广告样式为弹窗
                                        </>
                                    }
                                >
                                    <ExclamationCircleFilled />
                                </Tooltip>
                            </div>
                        </div>
                    </Col>


                </Row>

                {/* 开屏广告 */}
                {selectAdShowPosition === 'bootScreen' && (
                    <Row>
                        <Col span={6}>
                            <Form.Item name="appImgPath" label="移动端图片(尺寸要求：750*1442)" labelCol={{span:15}} rules={[{ required: true, message: "移动端图片必填" }]}>
                                <Image src={appImgPath ? imgIp + appImgPath : appImgPath} width={100} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="appImgPath" label="移动端图片上传">
                                <Upload {...appImgPathProps}>
                                    <Button style={{ width: 250 }} icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {/* 官网 */}
                {selectAdShowPosition === 'officialWebsite' && (
                    <Row>
                        <Col span={6}>
                            <Form.Item name="pcImgPath" label="图片(尺寸要求：460*460)" labelCol={{span:15}} rules={[{required: true, message: "图片必填"}]}>
                                <Image src={pcImgPath?imgIp+pcImgPath:pcImgPath} width={100}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="pcImgPath" label="图片上传">
                                <Upload {...pcImgPathProps} >
                                    <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="maxTimePerDay" label="推送频率"
                                       rules={[{required: true, message: "推送频率必填"}]}>
                                <Select
                                    style={{width: 250}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={maxTimePerDayOption}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="jumpLink" label="跳转链接" rules={[{required: true, message: "跳转链接必填"}]}>
                                <Input style={{width: 250}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {/* 首页 */}
                {selectAdShowPosition === 'homePage' && (
                    <div>
                        <Row>
                            <Col span={6}>
                                <Form.Item name="pcImgPath" label="pc端图片(尺寸要求：702*910)" labelCol={{span:15}} rules={[{required: true, message: "pc图片必填"}]}>
                                    <Image src={pcImgPath?imgIp+pcImgPath:pcImgPath} width={100}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="pcImgPath" label="pc端图片上传">
                                    <Upload {...pcImgPathProps} >
                                        <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="appImgPath" label="移动端图片(尺寸要求：460*460)" labelCol={{span:15}} rules={[{ required: true, message: "移动端图片必填" }]}>
                                    <Image src={appImgPath ? imgIp + appImgPath : appImgPath} width={100} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="appImgPath" label="移动端图片上传">
                                    <Upload {...appImgPathProps}>
                                        <Button style={{ width: 250 }} icon={<UploadOutlined />}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={6}>
                                <Form.Item name="maxTimePerDay" label="推送频率"
                                           rules={[{required: true, message: "推送频率必填"}]}>
                                    <Select
                                        style={{width: 250}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={maxTimePerDayOption}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="interactType" label="交互方式"
                                           rules={[{required: true, message: "交互方式必填"}]}>
                                    <Select
                                        style={{width: 250}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={advertInteractTypeOption}
                                        onChange={(value) => {
                                            setSelectAdvertInteractTypeOption(value); // 设置选择的值
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            {selectAdvertInteractTypeOption === 'jumpLink' && (
                                <Col span={6}>
                                    <Form.Item name="jumpLink" label="跳转链接" rules={[{required: true, message: "跳转链接必填"}, {max: 100, message: '跳转链接长度不能大于100'}]}>
                                        <Input style={{width: 250}}/>
                                    </Form.Item>
                                </Col>
                            )}

                            {selectAdvertInteractTypeOption === 'jumpPage' && (
                                <Col span={6}>
                                    <Form.Item name="jumpPage" label="跳转位置"
                                               rules={[{required: true, message: "跳转位置必填"}, {max: 100, message: '跳转位置长度不能大于100'}]}>
                                        <Select
                                            style={{width: 250}}
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={filterOption}
                                            options={appPageOption}
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                        </Row>
                    </div>
                )}
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default AdvertForm;



