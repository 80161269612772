const baseUrl = "/ord/promotion/month/statistics";
/** 获取列表-参数 */
export interface PageParam {
    yearMonth: string;
    userId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    userId: number;
    yearMonth: string;
    promotionUsers: number;
    promotionAmount: number;
    commission: number;
    creator: number;
    modifier: number;
    createTime: string;
    modifyTime: string;
    softDel: number;
    enableStatus: number;
}



export  {baseUrl};
