import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Upload,
    UploadProps
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/pdt/product";
import productApi from "../../../../apis/pdt/product";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import ProductAttributeContent from "./productAttribute/productAttributeContent";
import propertiesApi from "../../../../apis/sys/properties";
import {InboxOutlined, UploadOutlined} from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import {getToken} from "../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import pdtPictureApi from "../../../../apis/pdt/pdtPicture";
import pdtPicture from "../../../../apis/pdt/pdtPicture";
import {RcFile} from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../components/constantConfig";
import ProductCustomContent from "./custom/productCustomContent";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    productTypeCdOption: OptionRes[];
    displayModeCdOption: OptionRes[];
    ownerTypeCdOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const ProductFormFrom: React.FC<FromProps> = ({
                                                  refresh,
                                                  record,
                                                  open,
                                                  closeOpen,
                                                  model,
                                                  productTypeCdOption,
                                                  displayModeCdOption,
                                                  ownerTypeCdOption,
                                                  statusCdOption
                                              }) => {
    const [form] = Form.useForm();

    // 产品图标
    const [oldProductIcon, setOldProductIcon] = useState(record.productIcon);
    const [productIcon, setProductIcon] = useState(record.productIcon)
    const [productIconList, setProductIconList] = useState<UploadFile[]>([])
    // 产品主图
    const [oldProductImage, setOldProductImage] = useState(record.productImage);
    const [productImage, setProductImage] = useState(record.productImage)
    const [productImageList, setProductImageList] = useState<UploadFile[]>([])
    // 产品介绍图
    const [oldProductIntroImage, setOldProductIntroImage] = useState(record.productImage);
    const [productIntroImage, setProductIntroImage] = useState(record.productImage)
    const [productIntroImageList, setProductIntroImageList] = useState<UploadFile[]>([])
    // 产品角标图
    const [oldCornerIcon, setOldCornerIcon] = useState(record.cornerIcon);
    const [cornerIcon, setCornerIcon] = useState(record.cornerIcon)
    const [cornerIconList, setCornerIconList] = useState<UploadFile[]>([])

    // 产品相册相关useState
    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [savePictureUrlList, setSavePictureUrlList] = useState<String[]>([]);
    const [deletePictureIdList, setDeletePictureIdList] = useState<String[]>([]);
    const [oldPictureIdList, setOldPictureIdList] = useState<String[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');


    let productId = record.id;
    // 下拉框选项
    const [instanceGradeOption, setInstanceGradeOption] = useState<OptionRes[]>([])

    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
                defaultSelected: record.defaultSelected !== null && record.defaultSelected !== undefined
                    ? record.defaultSelected.toString() : null,
            })
            queryParams();
            // 产品id
            productId = record.id

        }
        setSavePictureUrlList([])
        if (model==='create'){
            // form.setFieldValue("relaObject",'I04')
            form.setFieldValue("productTypeCd",'P01')
            form.setFieldValue("ownerTypeCd",'O01')
            form.setFieldValue("customProduct",1)
        }
    }, [open]);


    useEffect(() => {

        // 在组件挂载时发送请求
        if (productId) {
            queryPicture();
            setProductIcon(record.productIcon)
            setOldProductIcon(record.productIcon)
            setOldProductIntroImage(record.productIntroImage)
            setProductIntroImage(record.productIntroImage)
            setOldProductImage(record.productImage)
            setProductImage(record.productImage)
            setOldCornerIcon(record.cornerIcon)
            setCornerIcon(record.cornerIcon)
        }

    }, [productId]); // 当 productId 变化时触发

    function queryParams() {
        propertiesApi.optinos('DM_Instance_Grade').then((res) => {
            if (res) {
                setInstanceGradeOption(res.data)
            }
        })
    }

    //处理新增方法
    const handleCreate = () => {
        const values = form.getFieldsValue();
        values.productIcon = productIcon;
        values.productImage = productImage;
        values.productIntroImage = productIntroImage;
        values.cornerIcon = cornerIcon;
        productApi.create(values).then((result) => {
            if (result) {
                message.success(`产品保存成功`);
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        setProductIcon("")
        setProductImage("")
        setCornerIcon("")
        setProductIntroImage("")
        setProductIconList([]);
        setProductImageList([]);
        setProductIntroImageList([]);
        setCornerIconList([]);
        setFileList([])
        setUrlMap(new Map());
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        values.productIcon = productIcon;
        values.productImage = productImage;
        values.cornerIcon = cornerIcon;
        values.productIntroImage = productIntroImage;
        let valueJSOn = JSON.stringify(values);
        productApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                // handleCancel();
                message.success(`产品保存成功`);
                refresh();
                setOldProductIcon(productIcon)
                setOldProductImage(productImage)
                setOldProductIntroImage(productIntroImage)
                setOldCornerIcon(cornerIcon)
                setProductIconList([])
                setProductImageList([])
                setProductIntroImageList([])
                setCornerIconList([])
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    // 产品图标上传
    const productIconprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: productIconList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            // setProductIconList([...productIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setProductIcon(urlRes)
                    setProductIconList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setProductIconList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setProductIcon(model === 'create' ? "" : oldProductIcon)
        },

    };// 产品图标上传


    const cornerIconprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: cornerIconList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            // setProductIconList([...productIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setCornerIcon(urlRes)
                    setCornerIconList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setCornerIconList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setCornerIcon(model === 'create' ? "" : oldCornerIcon)
        },

    };

    // 产品主图上传
    const productImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: productImageList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            // setProductIconList([...productIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setProductImage(urlRes)
                    setProductImageList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setProductImageList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setProductImage(model === 'create' ? "" : oldProductImage)
        },

    };

    // 产品介绍图片上传
    const productIntroImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: productIntroImageList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            // setProductIconList([...productIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setProductIntroImage(urlRes)
                    setProductIntroImageList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setProductIntroImageList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setProductIntroImage(model === 'create' ? "" : oldProductIntroImage)
        },

    };


    // 查询产品的图片
    function queryPicture() {
        const body = {
            objectId: productId,
            objectTypeCd: "O01"
        }
        let res = pdtPicture.queryData(body);
        res.then((result) => {
            if (result) {
                // 处理后端返回的数据
                const mappedData = result.data.map(item => ({
                    uid: item.id, // 使用id作为uid
                    name: item.pictureUrl.substring(item.pictureUrl.lastIndexOf('/') + 1), // 从图片URL中提取文件名
                    status: 'done',
                    url: item.pictureUrl,
                }));

                // 更新状态
                setFileList([...fileList, ...mappedData]);
                // 把查到的图片的id保存在一个oldPictureIdList列表里。怎么做？
                // 更新 oldPictureIdList 列表
                const idList = result.data.map(item => item.id);
                setOldPictureIdList([...oldPictureIdList, ...idList]);
            }
        })
    }

    function handlePictureOK() {
        // 保存新增的，删除被删除的
        if (savePictureUrlList.length > 0) {
            savePicture();
        }
        if (deletePictureIdList.length > 0) {
            deletePicture();
        }
        handleCancel()
    }

    function deletePicture() {
        pdtPictureApi.batchDelete(deletePictureIdList).then((result) => {
            if (result) {
                setDeletePictureIdList([]);
            }
        });
    }

    function savePicture() {
        // 遍历 savePictureUrlList 数组中的字符串
        savePictureUrlList.forEach((url) => {
            // 在这里对每个字符串进行操作
            console.log(url);

            // 可以在这里执行你的操作，比如发送请求等
            // 创建一个对象，包含多个属性
            const picture = {
                pictureUrl: url,
                pictureTypeCd: "P02",
                objectTypeCd: "O01",
                objectId: productId,
                pictureTag: "",
                //第一个是主要图片，0表示否，1表示是
                masterMark: "0",
                statusCd: "S01",
                notes: ""
            };

            pdtPictureApi.create(picture).then((result) => {
                if (result) {

                }
            });

        });
        setSavePictureUrlList([]);
    }

    // 产品相册上传逻辑
    const props: UploadProps = {
        multiple: true,
        name: 'file',
        maxCount: 1000,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        listType: "picture-card",

        fileList: fileList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {

            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    // 将urlRes添加到savePictureUrlList中
                    setSavePictureUrlList((prevList) => [...prevList, urlRes]);
                    urlMap.set(file.uid, urlRes);
                    setUrlMap(new Map(urlMap));
                    // setFileList([...fileList, file])
                    setFileList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            }

            if (status === 'uploading') {
                setFileList(prevList => [...prevList, file]);
            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }

            // setFileList(prevList => [...prevList, file]);
            setFileList([...fileList])
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            console.log("remove file", file.uid)

            // 将要删除的图片id记录下来。先判断
            if (oldPictureIdList.includes(file.uid)) {
                setDeletePictureIdList(prevList => [...prevList, file.uid]);
            } else {
                setSavePictureUrlList(prevList => prevList.filter(url => url !== file.response.data));
            }

            urlMap.delete(file.uid);
            setUrlMap(new Map(urlMap));

        },

    };

    // 图片展示
    const handleCancelPictureShow = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };


    // 中文字符算两个字节，其余字符算1个字节
    function getByteLength(str) {
        let byteLength = 0;
        for (let i = 0; i < str.length; i++) {
            byteLength += str.charCodeAt(i) > 0x7F ? 2 : 1;
        }
        return byteLength;
    }


    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            // footer={model === 'edit' ? null : undefined} // 设置为 null 取消自带按钮
            footer={null} // 设置为 null 取消自带按钮
            onCancel={handleCancel}
            onOk={handleOK}
            width={1600}
        >

            <div className={"product"}>
                <div className={"product-form-section"} style={{}}>
                    <h3>产品</h3>
                </div>
                <div className={"product-form-content"}>
                    <Form
                        {...layout}
                        form={form}
                        name="control-hooks"
                        initialValues={
                            model === 'create'
                                ? {productTypeCd: "P01", ownerTypeCd: "O01", customProduct: 1}
                                : {}
                        }
                    >

                        <Form.Item name="id" label="产品编号" hidden={true}>
                            <Input/>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="productName"
                                    label="产品名称"
                                    rules={[
                                        { required: true, message: '产品名称必填' },
                                        {
                                            max: 32,
                                            message: '产品名称长度不能大于128'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value) {
                                                    return Promise.resolve();
                                                }

                                                const byteLength = getByteLength(value);

                                                if (byteLength > 12) {
                                                    return Promise.reject(new Error('名称不能超过12个字节'));
                                                }

                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                ><Input style={{width: 200}} disabled={model === 'edit'}/></Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="productCode" label="产品编码"
                                           rules={[{required: true, message: "产品编码必填"}, {max: 32, message: '产品编码长度不能大于32'}]}>
                                    <Input style={{width: 200}} disabled={model === 'edit'}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="productTypeCd" label="产品类型"
                                           rules={[{required: true, message: "产品类型必填"}]}>
                                    <Select
                                        disabled
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={productTypeCdOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="unitPrice" label="标准价(分)"
                                           rules={[{required: true, message: "标准价必填"}]}>
                                    <InputNumber style={{width: 200}} min={1}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item name="relaObject" label="关联的对象"
                                           rules={[{required: true, message: "关联的对象必填"}]}>
                                    <Select
                                        disabled={model==="edit"}
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={instanceGradeOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="ownerTypeCd" label="产品归属类型"
                                           rules={[{required: true, message: "产品归属类型必填"}]}>
                                    <Select
                                        disabled
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={ownerTypeCdOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item name="customProduct" label="个性化类型"
                                           rules={[{required: true, message: "个性化类型必填"}]}>
                                    <Select
                                        disabled
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={[{value: 0, label: "常规"}, {value: 1, label: "定制化"}]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                                    <Select
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={statusCdOption}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>

                            <Col span={6}>
                                <Form.Item name="productDetail" label="产品详情">
                                    <Input style={{width: 200}}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="advertWords" label="产品广告词"
                                           rules={[{required: false},
                                               {
                                                   validator: (_, value) => {
                                                       if (!value) {
                                                           return Promise.resolve();
                                                       }

                                                       const byteLength = getByteLength(value);

                                                       if (byteLength > 28) {
                                                           return Promise.reject(new Error('产品广告词长度不能大于28个字节'));
                                                       }

                                                       return Promise.resolve();
                                                   }
                                               }
                                              ]}>
                                    <Input style={{width: 200}}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="productLabel" label="标签"
                                           rules={[{required: false},
                                               {
                                                   validator: (_, value) => {
                                                       if (!value) {
                                                           return Promise.resolve();
                                                       }

                                                       const byteLength = getByteLength(value);

                                                       if (byteLength > 18) {
                                                           return Promise.reject(new Error('产品标签不能大于18个字节'));
                                                       }

                                                       return Promise.resolve();
                                                   }
                                               }
                                              ]}>
                                    <Input style={{width: 200}}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="notes" label="备注">
                                    <TextArea rows={1} style={{width: 200}}/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item name="defaultSelected" label="默认选中">
                                    <Select
                                        style={{width: 200}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={[
                                            {value: '1', label: '是'},
                                            {value: '0', label: '否'},
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="reorder" label="排序">
                                    <InputNumber style={{width: 200}} min={0}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="cornerIconUplod" label="产品角标上传">
                                    <Upload {...cornerIconprops}>
                                        <Button style={{width: 200}} icon={<UploadOutlined/>}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="cornerIcon" label="产品角标">
                                    <Image src={imgIp + cornerIcon} width={100}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row gutter={16}>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productIconUplod" label="产品图标上传">*/}
                        {/*            <Upload {...productIconprops}>*/}
                        {/*                <Button icon={<UploadOutlined/>}>点击上传</Button>*/}
                        {/*            </Upload>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productIcon" label="产品图标">*/}
                        {/*            <Image src={imgIp+productIcon} width={100}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productImageUplod" label="产品主图上传">*/}
                        {/*            <Upload {...productImageprops}>*/}
                        {/*                <Button icon={<UploadOutlined/>}>点击上传</Button>*/}
                        {/*            </Upload>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productImage" label="产品主图">*/}
                        {/*            <Image src={imgIp+productImage} width={100}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row gutter={16}>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productIntroImageUplod" label="产品介绍图片上传">*/}
                        {/*            <Upload {...productIntroImageprops}>*/}
                        {/*                <Button icon={<UploadOutlined/>}>点击上传</Button>*/}
                        {/*            </Upload>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={6}>*/}
                        {/*        <Form.Item name="productIntroImage" label="产品介绍图片">*/}
                        {/*            <Image src={imgIp+productIntroImage} width={100}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*// TODO 产品归属的对象*/}
                    </Form>
                </div>
                <div className={"product-form-button"} style={{textAlign: 'center', marginTop: '10px'}}>
                    <Button type="primary" ghost onClick={() => {
                        handleOK()
                    }} style={{marginRight: '25px'}}>
                        保存产品
                    </Button>
                </div>
            </div>

            {model === 'edit' && (
                <>
                    <Divider/>

                    <div className={"product-attribute"}>
                        <div className={"product-attribute-form-section"} style={{}}>
                            <h3>产品规格</h3>
                        </div>
                        <div className={"product-attribute-content"}>
                            <ProductAttributeContent
                                productId={productId}
                            ></ProductAttributeContent>
                        </div>
                    </div>
                    {/*定制化产品才有这个选项*/}
                    {record.customProduct === 1 && (
                        <>
                            <Divider/>
                            <div className={"product-attribute"}>
                                <div className={"product-attribute-form-section"} style={{}}>
                                    <h3>定制化应用</h3>
                                </div>
                                <div className={"product-attribute-content"}>
                                    <ProductCustomContent
                                        productId={productId}
                                    ></ProductCustomContent>
                                </div>
                            </div>
                        </>
                    )}

                    <Divider/>

                    <div className={"product-picture"}>
                        <div className={"product-picture-form-section"} style={{}}>
                            <h3>产品图片</h3>
                        </div>
                        <div className={"product-picture-content"}>
                            <Form.Item>
                                <Dragger
                                    {...props}
                                    onPreview={handlePreview}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">点击或者拖拉图片上传</p>
                                    <p className="ant-upload-hint">
                                        支持单个，多个图片上传
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </div>
                        <div className={"product-picture-button"} style={{textAlign: 'center'}}>
                            <Button type="primary" ghost onClick={() => {
                                handlePictureOK()
                            }} style={{marginRight: '25px'}}>
                                保存图片
                            </Button>
                        </div>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelPictureShow}>
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>

                </>
            )}
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ProductFormFrom;



