import {request} from "../../utils";
import {TableDataType, baseUrl} from "../types/res/insAdminGrantVo";
import {PageParam} from "../types/res/InsTask";
import {PaginationProps} from "antd";
import {PageRes} from "../types/common";
import commonApi from "../commonApi";

function isValidTimeFormat(value) {
    // Assuming a simple check for the 'YYYY-MM-DD HH:mm:ss' format
    const timeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return timeRegex.test(value);
}

const insAdminGrantApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        // 添加 body 中不为空的字段
        console.log("body:" + body)
        for (const key in body) {
            console.log("key:" +  key + ":" + body[key])
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                let valueToEncode = body[key];

                // Check if the value is not in 'YYYY-MM-DD HH:mm:ss' time format
                if (!isValidTimeFormat(valueToEncode)) {
                    valueToEncode = encodeURIComponent(valueToEncode.toString());
                }

                queryParams.set(key, valueToEncode);
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);

    },

    // 获取授权所需的相关信息
    queryGrantForm(insId:number) {
        let url = baseUrl + "/insAdminGrantForm";
        url += "?"
        if (insId){
            url += 'insId=' + encodeURIComponent(insId);
        }

        return request.get(url)
    },

    insGrant(body: { insId: number; granteePhoneNumber: String; grantTimeInMinutes: number }) {
        let url = baseUrl + "/" +  "insAdminGrant";

        // 创建 FormData 对象
        const formData = new FormData();

        // 添加参数到 FormData
        formData.append('insId', body.insId);
        formData.append('granteePhoneNumber', body.granteePhoneNumber);
        formData.append('grantTimeInMinutes', body.grantTimeInMinutes);

        // 发起 POST 请求
        return request.post(url, formData);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"云手机授权列表",body)
    },
    //批量解除管理员授权
    batchRevoke(idList: Number[]) {
        let url = baseUrl + "/revokeAdminGrant";
        return request.post(url, idList);
    },
}

export default insAdminGrantApi



