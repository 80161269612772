import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";
import {PageParam, baseUrl, TableDataType} from "../types/sub/SubAgentUser";

const subAgentUserApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.userName) {
                url += 'userName=' + encodeURIComponent(body.userName) + "&";
            }
            if (body.price) {
                url += 'price=' + encodeURIComponent(body.price) + "&";
            }
            if (body.poolId) {
                url += 'poolId=' + encodeURIComponent(body.poolId) + "&";
            }
            if (body.notes) {
                url += 'notes=' + encodeURIComponent(body.notes) + "&";
            }
            if (body.userCode) {
                url += 'userCode=' + encodeURIComponent(body.userCode) + "&";
            }
            if (body.redeemType) {
                url += 'redeemType=' + encodeURIComponent(body.redeemType) + "&";
            }
            if (body.goodsId) {
                url += 'goodsId=' + encodeURIComponent(body.goodsId) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/category';
        return request.put(url, body)
    },
    //查询列表
    // options(body: any) {
    //     let url = baseUrl;
    //     url += "/options"
    //     body = {...body, "organType": "O01"}
    //     return request.post<OptionRes[]>(url, body)
    // },
    derive(body: PageParam) {
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url, "代理商列表", body)
    },

}

export default subAgentUserApi



