import React from "react";
import VueIframe from "../../../../components/VueIframe";
import { vueIframeDomain } from "../../../../components/constantConfig";

const VueIframeDemo = () => {
  return (
    <VueIframe idKey={"actManage"} src={vueIframeDomain + "/market/activity"} />
  );
};

export default VueIframeDemo;
