const envConfigs = {
    local: {
        domain: 'http://localhost:8080/cpWeb',
        imgIp: 'http://localhost/',
        vueIframeDomain: 'http://localhost/vue/#',
    },
    jizhi: {
        domain: 'http://192.168.168.114/cpWeb',
        imgIp: 'http://192.168.168.114/',
        vueIframeDomain: 'http://192.168.30.46:7000/vue/#',
        // vueIframeDomain: 'http://192.168.168.114/vue/#',
    },
    test: {
        domain: 'https://testcgwdebug.ntimespace.com/cpWeb',
        imgIp: 'https://testcgwdebug.ntimespace.com/',
        vueIframeDomain: 'https://testcgwdebug.ntimespace.com/vue/#',
    },
    stress: {
        domain: 'http://teststressdebug.ntimespace.com/cpWeb',
        imgIp: 'http://teststressdebug.ntimespace.com/',
        vueIframeDomain: 'http://teststressdebug.ntimespace.com/vue/#',
    },
    pre: {
        domain: 'http://sandboxcgw.ntimespace.com/cpWeb',
        imgIp: 'http://sandboxcgw.ntimespace.com/',
        vueIframeDomain: 'http://sandboxcgw.ntimespace.com/vue/#',
    },
    greentest: {
        domain: 'https://greentest.palmderive.com/cpWeb',
        imgIp: 'https://greentest.palmderive.com/',
        vueIframeDomain: 'https://greentest.palmderive.com/vue/#',
    },
    zhangpai: {
        domain: 'https://bsmpapi.palmderive.com/cpWeb',
        imgIp: 'https://bsmpapi.palmderive.com/',
        vueIframeDomain: 'https://bsmpapi.palmderive.com/vue/#',
    },
    kuajing: {
        domain: 'https://bsmpapi.palmpai.com/cpWeb',
        imgIp: 'https://bsmpapi.palmpai.com/',
        vueIframeDomain: 'https://bsmpapi.palmpai.com/vue/#',
    },
    jizhi: {
        domain: 'https://bsmpapi.jzyhui.com/cpWeb',
        imgIp: 'https://bsmpapi.jzyhui.com/',
        vueIframeDomain: 'https://bsmpapi.jzyhui.com/vue/#',
    },
    tianxinyun: {
        domain: 'https://bsmpapi.tianxinserver.com/cpWeb',
        imgIp: 'https://bsmpapi.tianxinserver.com/',
        vueIframeDomain: 'https://bsmpapi.tianxinserver.com/vue/#',
    },
    youdayun: {
        domain: 'https://bsmpapi.yoodacloud.com/cpWeb',
        imgIp: 'https://bsmpapi.yoodacloud.com/',
        vueIframeDomain: 'https://bsmpapi.yoodacloud.com/vue/#',
    },
    yingguoyun: {
        domain: 'https://bsmpapi.chaak.io/cpWeb',
        imgIp: 'https://bsmpapi.chaak.io/',
        vueIframeDomain: 'https://bsmpapi.chaak.io/vue/#',
    }
};

// const envName = "local";
const envName = "jizhi";
// const envName = "test";
// const envName = "stress";
// const envName = "pre";
// const envName = "zhangpai";
// const envName = "kuajing";
// const envName = "jizhi";
// const envName = "tianxinyun";
// const envName = "youdayun";
// const envName = "yingguoyun";

const { domain, imgIp,vueIframeDomain } = envConfigs[envName] || envConfigs.local;

export { domain, imgIp, vueIframeDomain };